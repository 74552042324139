import { Stack, Typography } from "@mui/material";
import { NavButtons } from "../../../base/navButtons";
import { PrintPartsLabelsButton } from "../../print/printPartsLabelsButton";
import { OngoingAuditsResolver } from "./resolver";
import { CreateAuditButton } from "./buttons";
import { DspAuditProvider } from "./context";

function AuditsView(): JSX.Element {
    return (
        <DspAuditProvider>
            <Stack direction="column">
                <Stack direction="row">
                    <Typography
                        variant="h6"
                        sx={{
                            flexGrow: 1
                        }}
                        >
                        Inventory Audits
                    </Typography>
                    <NavButtons
                        showSetup={true}
                        setupPath="/parts/setup"
                        >
                        <PrintPartsLabelsButton />
                        <CreateAuditButton />
                    </NavButtons>
                </Stack>
                <OngoingAuditsResolver />
            </Stack>
        </DspAuditProvider>
    );
}

export { AuditsView }