import React from "react";
import { useApiService } from "../../base/providers";
import { SetDspAuditFlagResponse } from "types/admin";

type DspAuditFlagContextType = {
    formState: DspAuditAdminFormStateType
    setDealerId: (dealerId: string) => void
    setLocationId: (locationId: number) => void
    setIsEnabled: (isEnabled: boolean) => void
    submitForm: () => Promise<SetDspAuditFlagResponse>
}

const DspAuditFlagContext = React.createContext<DspAuditFlagContextType | undefined>(undefined);

type DspAuditFlagProviderProps = {
    children: React.ReactNode
}

type DspAuditAdminFormStateType = {
    dealerId: string
    locationId: number
    isEnabled: boolean
}

function DspAuditFlagProvider(props: DspAuditFlagProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [formState, setFormState] = React.useState<DspAuditAdminFormStateType>({
        dealerId: "",
        locationId: 0,
        isEnabled: false
    });

    const setDealerIdCallback = React.useCallback((dealerId: string): void => {
        setFormState(prevState => {
            return {
                ...prevState,
                dealerId: dealerId
            };
        });
    }, []);

    const setLocationIdCallback = React.useCallback((locationId: number): void => {
        setFormState(prevState => {
            return {
                ...prevState,
                locationId: locationId
            };
        });
    }, []);

    const setIsEnabledCallback = React.useCallback((isEnabled: boolean): void => {
        setFormState(prevState => {
            return {
                ...prevState,
                isEnabled: isEnabled
            };
        });
    }, []);

    const submitFormCallback = React.useCallback((): Promise<SetDspAuditFlagResponse> => {
        return new Promise(async (resolve, reject) => {
            try {
                const { dealerId, locationId, isEnabled } = formState;
                const response = await apiService.admin.setDspAuditFeatureFlag(dealerId, locationId, isEnabled);
                resolve(response);
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService, formState]);

    return (
        <DspAuditFlagContext.Provider
            value={{
                formState: formState,
                setDealerId: setDealerIdCallback,
                setLocationId: setLocationIdCallback,
                setIsEnabled: setIsEnabledCallback,
                submitForm: submitFormCallback
            }}
            >
            {props.children}
        </DspAuditFlagContext.Provider>
    );
}

function useDspAuditFlagContext(): DspAuditFlagContextType {
    const context = React.useContext(DspAuditFlagContext);

    if (!context) {
        throw new Error("useAdminContext must be used within a AdminProvider");
    }

    return context;
}

export { DspAuditFlagProvider, useDspAuditFlagContext }