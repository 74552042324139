import { Chip } from "@mui/material";

type AuditStatusResolverProps = {
    status: number
}

function AuditStatusResolver(props: AuditStatusResolverProps): JSX.Element {
    switch (props.status) {
        case 0: // Not Started
            return (
                <Chip
                    color="primary"
                    label="Not Started"
                    />
            );
        case 1: // In Progress
            return (
                <Chip
                    color="warning"
                    label="In Progress"
                    />
            );
        case 2: // Completed
            return (
                <Chip
                    color="success"
                    label="Completed"
                    />
            );
        case 3: // Cancelled
        default:
            return (
                <Chip
                    color="error"
                    label="Cancelled"
                    />
            );
    }
}

export { AuditStatusResolver }