import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { useDspAuditSelectionContext } from "../context";

function SelectionNavigationView(): JSX.Element {
    const { isReadOnly, selectableBins, selectedBins, toggleSelectAllRows } = useDspAuditSelectionContext();

    const onClickSelectAll = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        toggleSelectAllRows();
    }

    return (
        <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            >
            <Box flex="1">
                <Button>
                    Bins List&nbsp;
                    <Chip
                        label={selectableBins.length}
                        size="small"
                        sx={{
                            marginLeft: 1
                        }}
                        />
                </Button>
                <Button>
                    Parts List&nbsp;
                    <Chip
                        label="0"
                        size="small"
                        sx={{
                            marginLeft: 1
                        }}
                        />
                </Button>
            </Box>
            <Typography
                variant="body2"
                style={{
                    fontWeight: "bold"
                }}
                >
                Parts/Bins added to Audit&nbsp;
                <Chip
                    label={selectedBins.size}
                    size="small"
                    sx={{
                        marginLeft: 1
                    }}
                    />
            </Typography>
            <Button
                disabled={isReadOnly}
                onClick={onClickSelectAll}
                variant="contained"
                >
                Select All
            </Button>
        </Stack>
    );
}

export { SelectionNavigationView }