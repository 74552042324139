import React from "react";
import { useApiService } from "../../../../base/providers";
import { useParams } from "react-router-dom";
import { AuditVarianceTabType } from "../types";
import { DspAuditBinSimple, DspAuditVariance, DspAuditVarianceChange, GetDspAuditVarianceResponse } from "types/dsp/audits";
import { auditVarianceInitialState, AuditVarianceReducer } from "./reducer";

type DspAuditVarianceContextType = {
    auditId: string
    auditName: string
    auditVariances: DspAuditVariance[]
    auditVarianceType: AuditVarianceTabType
    hasHandwrites: boolean
    selectedPending: Set<number>
    selectedRescan: Set<number>
    getQuantity: (lightyearId: number) => number
    onChangeIsPendingVarianceSelected: (lightyearId: number) => void
    onChangeIsRescanVarianceSelected: (lightyearId: number) => void
    requestRescan: () => Promise<void>
    resetNewQuantity: (lightyearId: number) => void
    setAuditVarianceType: (type: AuditVarianceTabType) => void
    setNewQuantity: (lightyearId: number, newQuantity: number) => void
    updateBinVariance: () => Promise<void>
}

const DspAuditVarianceContext = React.createContext<DspAuditVarianceContextType | undefined>(undefined);

type DspAuditVarianceProviderProps = {
    children: React.ReactNode
}

function DspAuditVarianceProvider(props: DspAuditVarianceProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const { auditId } = useParams();
    const [state, dispatch] = React.useReducer(AuditVarianceReducer, auditVarianceInitialState);

    const hasHandwritesMemo: boolean = React.useMemo((): boolean => {
        if (!state.auditVariances || !state.auditVarianceQuantities) {
            return false;
        }

        const hasHandwrites = state.auditVariances.filter((value: DspAuditVariance) => state.selectedPending.has(value.lightyearPartId))
            .some((value: DspAuditVariance): boolean => {
                const mapValue = state.auditVarianceQuantities.get(value.lightyearPartId);

                // DM 11/08/2024 Check for undefined: Instead of just mapValue, which would treat 0 
                // as falsy in a boolean context, it's better to explicitly check if mapValue is not undefined.
                // This ensures that even if the map holds 0 as a value, it will still be considered in the comparison.
                if (mapValue === undefined) {
                    return false;
                }

                return value.scannedCount !== mapValue;
            });

        return hasHandwrites;
    }, [state.auditVariances, state.auditVarianceQuantities, state.selectedPending]);

    const getAuditVariancesCallback = React.useCallback((): void => {
        if (!auditId) {
            return;
        }

        apiService.dsp.getAuditVariance(auditId)
            .then((response: GetDspAuditVarianceResponse) => dispatch({ type: "SET_AUDIT_VARIANCE", payload: response }))
            .catch((err) => console.error("Unable to get audit", err));
    }, [apiService, auditId]);

    const updateBinVarianceCallback = React.useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            const mappedVariances = state.auditVariances
                .filter((value: DspAuditVariance) => state.selectedPending.has(value.lightyearPartId))
                .map((value: DspAuditVariance): DspAuditVarianceChange => {
                    return {
                        auditBinId: value.bins[0].binId,
                        lightyearId: value.lightyearPartId,
                        newOnHand: state.auditVarianceQuantities.get(value.lightyearPartId) || value.scannedCount
                    }; 
                });
            
            apiService.dsp.updateBinVariance(mappedVariances)
                .then(() => getAuditVariancesCallback())
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, getAuditVariancesCallback, state.auditVariances, state.auditVarianceQuantities, state.selectedPending]);

    const requestRescanCallback = React.useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            const rescanVarianceBinIds = state.auditVariances
                .filter((value: DspAuditVariance) => state.selectedRescan.has(value.lightyearPartId))
                .flatMap((value: DspAuditVariance) => value.bins)
                .flatMap((value: DspAuditBinSimple) => value.binId);
            
            apiService.dsp.flagBinsForRescan(rescanVarianceBinIds)
                .then(() => getAuditVariancesCallback())
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, getAuditVariancesCallback, state.auditVariances, state.selectedRescan]);

    const setAuditVarianceTypeCallback = React.useCallback((type: AuditVarianceTabType): void => {
        dispatch({ type: "SET_TYPE", payload: type });
    }, []);

    const onChangeIsPendingVarianceSelectedCallback = React.useCallback((lightyearId: number) => {
        dispatch({ type: "CHANGE_IS_PENDING_VARIANCE_SELECTED", payload: lightyearId });
    }, []);

    const onChangeIsRescanVarianceSelectedCallback = React.useCallback((lightyearId: number) => {
        dispatch({ type: "CHANGE_IS_RESCAN_VARIANCE_SELECTED", payload: lightyearId });
    }, []);

    const setNewQuantityCallback = React.useCallback((lightyearId: number, newQuantity: number): void => {
        dispatch({ type: "CHANGE_VARIANCE_QUANTITY", payload: { lightyearId, newQuantity } });
    }, []);

    const resetNewQuantityCallback = React.useCallback((lightyearId: number): void => {
        dispatch({ type: "RESET_VARIANCE_QUANTITY", payload: lightyearId });
    }, []);

    const getQuantityCallback = React.useCallback((lightyearId: number): number => {
        var quantity = state.auditVarianceQuantities.get(lightyearId);

        return quantity || 0;
    }, [state.auditVarianceQuantities]);

    React.useEffect(() => {
        getAuditVariancesCallback();
    }, [getAuditVariancesCallback]);

    return (
        <DspAuditVarianceContext.Provider
            value={{
                auditId: auditId || "",
                auditName: state.auditName,
                auditVariances: state.auditVariances,
                auditVarianceType: state.auditVarianceType,
                hasHandwrites: hasHandwritesMemo,
                selectedPending: state.selectedPending,
                selectedRescan: state.selectedRescan,
                getQuantity: getQuantityCallback,
                onChangeIsPendingVarianceSelected: onChangeIsPendingVarianceSelectedCallback,
                onChangeIsRescanVarianceSelected: onChangeIsRescanVarianceSelectedCallback,
                requestRescan: requestRescanCallback,
                resetNewQuantity: resetNewQuantityCallback,
                setAuditVarianceType: setAuditVarianceTypeCallback,
                setNewQuantity: setNewQuantityCallback,
                updateBinVariance: updateBinVarianceCallback,
            }}
            >
            {props.children}
        </DspAuditVarianceContext.Provider>
    );
}

function useDspAuditVarianceContext(): DspAuditVarianceContextType {
    const context = React.useContext(DspAuditVarianceContext);

    if (!context) {
        throw new Error("useDspAuditVarianceContext must be used within a DspAuditVarianceProvider");
    }

    return context;
}

export { DspAuditVarianceProvider, useDspAuditVarianceContext }