import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";

function BackToAuditHistoryButton(): JSX.Element {
    const navigate = useNavigate();

    const onClickGoBack = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate("/vehicles/audits");
    }

    return (
        <Button
            onClick={onClickGoBack}
            variant="text"
            sx={{
                alignSelf: "flex-start",
                width: "auto"
            }}
            >
            &lt; Back to Audit History
        </Button>
    )
}

export { BackToAuditHistoryButton }