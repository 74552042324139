import React from 'react';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Tooltip } from '@mui/material';

type OnHoldFlagProps = {
    onHoldCount: number
}

function OnHoldFlag({ onHoldCount }: OnHoldFlagProps): JSX.Element {
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            if (onHoldCount === 0) {
                return (<></>);
            }

            return (
                <Tooltip title={`${onHoldCount} on hold`}>
                    <AssignmentIndIcon color="error" />
                </Tooltip>
            );
        });
    }, [onHoldCount]);

    return (component);
}

export { OnHoldFlag }