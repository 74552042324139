import { GridColDef } from "@mui/x-data-grid";
import { AuditDeleteButton, AuditDetailsButton } from "../buttons";
import { AuditStatusResolver } from "../statusResolver";
import { Utilities } from "../../../../../../utilities";

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        hideable: true,
        width: 90
    },
    {
        field: "auditName",
        flex: 1,
        headerName: "Audit Name",
        hideable: false,
        renderCell: (params) => (
            <AuditDetailsButton
                auditId={params.row.id}
                auditName={params.value}
                />
        )
    },
    {
        field: "status",
        flex: 0.75,
        headerName: "Status",
        renderCell: (params) => (
            <AuditStatusResolver status={params.value} />
        )
    },
    {
        field: "totalItems",
        flex: 1,
        headerName: "Total Bins/Parts",
        hideable: false
    },
    {
        field: "totalAmount",
        flex: 1,
        headerName: "Total Audit Amount",
        hideable: false,
        renderCell: (params) => (
            <span>{Utilities.displayAsCurrency(params.value)}</span>
        )
    },
    {

        field: "dateCreated",
        flex: 1,
        headerName: "Date Created",
        hideable: false,
        renderCell: (params) => {
            const date = new Date(params.value);
            return (<span>{date.toLocaleString()}</span>);
        }
    },
    {
        field: "deleteId",
        filterable: false,
        headerName: "",
        hideable: false,
        renderCell: (params) => (
            <AuditDeleteButton
                deleteId={params.value}
                disabled={params.row.type === "finished"}
                />
        ),
        sortable: false,
        width: 100
    },
    {
        field: "type",
        filterable: false,
        flex: 1,
        hideable: true
    }
];

export { columns }