import React from "react";
import { GenericDataGridFilterable } from "../../../../../base/dataGrid";
import { useDspAuditDetailsContext } from "../../context";
import { columns } from "./columns";
import { MappedRow } from "./mappedRow";
import { DspAuditVarianceReport } from "types/dsp/audits";
import { predefinedFilters } from "./filters";

function CompleteDetailsDataGrid(): JSX.Element {
    const { auditStatus, auditVariances } = useDspAuditDetailsContext();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        setMappedRows(auditVariances.map((value: DspAuditVarianceReport, index: number) => {
            return {
                id: index,
                partNumber: value.partNumber,
                bins: value.bins,
                partCost: value.partCost,
                expected: value.expected,
                scanned: value.scanned,
                quantityVariance: value.newQuantity
                    ? value.newQuantity - value.expected
                    : value.scanned - value.expected,
                dollarVariance: value.newQuantity
                    ? (value.newQuantity - value.expected) * value.partCost
                    : (value.scanned - value.expected) * value.partCost,
                newQuantity: value.newQuantity ? value.newQuantity : null,
                isVariance: value.expected !== value.scanned,
                override: value.newQuantity !== null && value.scanned !== value.newQuantity,
                reconciled: value.newQuantity !== null
            };
        }));
    }, [auditVariances, auditStatus]);

    return (
        <GenericDataGridFilterable
            columns={columns}
            columnVisibilityModel={{
                id: false,
                isVariance: false
            }}
            filterModels={predefinedFilters}
            rows={mappedRows}
            />
    );
}

export { CompleteDetailsDataGrid }