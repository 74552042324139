import axios from "axios";

class LocalNetworkService {
    sendToPrinter(printJob: string, host: string, port: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(`http://${host}:${port}`, printJob, {
                    headers: {
                        "Content-Type": "text/plain"
                    }
                });

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }
}

export { LocalNetworkService }