import { PreDefinedFilterModel } from "../../../../../../base/dataGrid";


const predefinedFilters: PreDefinedFilterModel[] = [
    {
        label: "variances",
        filterModel: {
            items: [
                { id: 1, field: "isVariance", operator: "equals", value: "true" }
            ]
        }
    }
];

export { predefinedFilters }