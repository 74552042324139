import React, { useImperativeHandle } from "react";
import { Box } from "@mui/material";
import { PrintableTemplateHandle, PrintableTemplateProps } from "../../../../types/print/PrintableTemplateHandle";
import { StockLabelPage } from "./stockLabelPage";
import { PartDetails } from "../../../../types/dsp/print";
import { useReactToPrint } from "react-to-print";

type StockLabelTemplateProps = PrintableTemplateProps & {
    partDetails: PartDetails[]
}

const StockLabelTemplate = React.forwardRef<PrintableTemplateHandle, StockLabelTemplateProps>((props, ref) => {
    const stockLabelTemplateRef = React.useRef<HTMLDivElement>(null);
    const [stockLabelPages, setStockLabelPages] = React.useState<JSX.Element[]>([]);
    const reactToPrintFn = useReactToPrint({ contentRef: stockLabelTemplateRef });

    useImperativeHandle(ref, () => ({
        print: () => {
            if (!stockLabelTemplateRef || !stockLabelTemplateRef.current) {
                return;
            }

            reactToPrintFn();
        }
    }));

    React.useEffect(() => {
        setStockLabelPages(props.partDetails.map((part: PartDetails, index: number): JSX.Element => {
            return (
                <StockLabelPage
                    bin={part.binOne}
                    description={part.description}
                    key={index}
                    partNumber={part.partNumber}
                    />
            );
        }));
    }, [props.partDetails])

    return (
        <Box
            ref={stockLabelTemplateRef}
            id="label-container"
            >
            {stockLabelPages}
        </Box>
    );
});

export { StockLabelTemplate }