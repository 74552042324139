import React from "react";
import { useGridApiRef } from "@mui/x-data-grid";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { filterInitialState, FilterReducer } from "./reducer";
import { GenericDataGridFilterableProps, PreDefinedFilterModel } from "..";
import { FilterOptionButton } from "../buttons";

type FilterableDataGridContextType = GenericDataGridFilterableProps & {
    apiRef: React.MutableRefObject<GridApiCommunity>
    filterButtons: JSX.Element[]
}

const FilterableDataGridContext = React.createContext<FilterableDataGridContextType | undefined>(undefined);

type FilterableDataGridProviderProps = GenericDataGridFilterableProps & {
    children: JSX.Element
}

function FilterableDataGridProvider(props: FilterableDataGridProviderProps): JSX.Element {
    const apiRef = useGridApiRef();
    const [state, dispatch] = React.useReducer(FilterReducer, filterInitialState);

    const setFilterOptionCallback = React.useCallback((filterModel: PreDefinedFilterModel): void => {
        dispatch({ type: "SET_FILTER_MODEL", payload: filterModel });
    }, []);

    React.useEffect(() => {
        dispatch({ type: "UPDATE_FILTER_MODEL_ARR", payload: props.filterModels });
    }, [props.filterModels]);

    React.useEffect(() => {
        if (!state.filter) {
            return;
        }

        apiRef.current.setFilterModel(state.filter.filterModel);
    }, [apiRef, state.filter]);

    React.useEffect(() => {
        if (!state.filterModels) {
            return;
        }

        const filterButtons = state.filterModels.map((value: PreDefinedFilterModel, index: number): JSX.Element => {
            const { filteredRowsLookup } = apiRef.current.getFilterState(value.filterModel);
            const filteredRows = Object.keys(filteredRowsLookup).filter((rowId: string) => filteredRowsLookup[rowId] === true);

            return (
                <FilterOptionButton
                    key={index}
                    count={filteredRows.length}
                    label={value.label}
                    variant={state.filter?.label === value.label ? "outlined" : "text"}
                    onClickFilterOptionCallback={() => setFilterOptionCallback(value)}
                    />
            );
        });

        dispatch({ type: "SET_FILTER_BUTTONS", payload: filterButtons });

    }, [apiRef, state.filterModels, state.filter, setFilterOptionCallback]);
    
    return (
        <FilterableDataGridContext.Provider
            value={{
                apiRef: apiRef,
                columns: props.columns,
                columnVisibilityModel: props.columnVisibilityModel,
                filterButtons: state.filterButtons,
                initialStatePagination: props.initialStatePagination,
                initialStateSorting: props.initialStateSorting,
                pageSizeOptions: props.pageSizeOptions,
                rows: props.rows,
                slots: props.slots,
                onColumnVisibilityModelChange: props.onColumnVisibilityModelChange
            }}
            >
            {props.children}
        </FilterableDataGridContext.Provider>
    );
}

function useFilterableDataGridContext(): FilterableDataGridContextType {
    const context = React.useContext(FilterableDataGridContext);

    if (!context) {
        throw new Error("useFilterableDataGridContext must be used within a FilterableDataGridProvider");
    }

    return context;
}

export {
    FilterableDataGridProvider,
    useFilterableDataGridContext
}