import React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Tooltip } from '@mui/material';

type HasMultipleBinsFlagProps = {
    hasMultipleBins: boolean
}

function HasMultipleBinsFlag({ hasMultipleBins }: HasMultipleBinsFlagProps): JSX.Element {
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            if (!hasMultipleBins) {
                return (<></>);
            }

            return (
                <Tooltip title={`Assigned to multiple bins`}>
                    <ViewListIcon color="primary" />
                </Tooltip>
            );
        });
    }, [hasMultipleBins]);

    return (component);
}

export { HasMultipleBinsFlag }