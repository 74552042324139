import { AxiosInstance } from "axios";
import { ApiService } from "./apiService";
import { GetUserRolesResponse, SetDspAuditFlagResponse } from "types/admin";

class AdminApiService extends ApiService {
    super(api: AxiosInstance) { }

    getUserRoles(): Promise<GetUserRolesResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/auth/getuserroles");
    
                const getUserRolesResponse = response.data as GetUserRolesResponse;
                resolve(getUserRolesResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    setDspAuditFeatureFlag(dealerId: string, locationId: number, isEnabled: boolean): Promise<SetDspAuditFlagResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("/admin/setdspauditfeatureflag", {
                    dealerId: dealerId,
                    locationId: locationId,
                    isEnabled: isEnabled
                });

                const setDspAuditFeatureFlagResponse = response.data as SetDspAuditFlagResponse;
                resolve(setDspAuditFeatureFlagResponse);
            } catch (err) {
                reject(err);
            }
        });
    }
}

export { AdminApiService }