import React from "react";
import { useApiService } from "../../../base/providers";
import { DsvPrinter } from "../../../../types/dsv/setup";
import { CreatePrinterForm, CreatePrinterFormValues } from "./createPrinterForm";
import { CreatePrinterButton } from "./createPrinterButton";
import { Box, Stack, Typography } from "@mui/material";
import { PrinterSetupDataGrid } from "./printerSetupDataGrid";
import { GenericModal } from "../../../base/modal";
import { DsvPrinterType } from "../../../../types/dsv/printerTypes";

function PrinterSetup(): JSX.Element {
    const { apiService } = useApiService();
    const [printers, setPrinters] = React.useState<DsvPrinter[]>([]);
    const [showCreatePrinterModal, setShowCreatePrinterModal] = React.useState(false);

    const getAllPrintersCallback = React.useCallback(() => {
        apiService.dsv.getAllPrinters()
            .then((dsvPrinterArr) => setPrinters(dsvPrinterArr))
            .catch((err) => console.error("Unable to retrieve printers", err));
    }, [apiService, setPrinters]);

    const showCreatePrinterModalCallback = React.useCallback(() => {
        setShowCreatePrinterModal(true);
    }, [setShowCreatePrinterModal])

    const hideCreatePrinterModalCallback = React.useCallback(() => {
        setShowCreatePrinterModal(false);
    }, [setShowCreatePrinterModal]);

    const submitCreatePrinterModalCallback = React.useCallback((formValues: CreatePrinterFormValues): void => {
        const printerTypeNum = Number(formValues.printerType);

        if (isNaN(printerTypeNum) || !DsvPrinterType.isValidMapping(printerTypeNum)) {
            console.error("Printer type is not valid", formValues.printerType);
            return;
        }

        apiService.dsv.addPrinter(formValues.printerName, printerTypeNum, formValues.host)
            .then(() => getAllPrintersCallback())
            .then(() => hideCreatePrinterModalCallback())
            .catch((err) => console.error("Unable to add printer", err));
    }, [apiService, getAllPrintersCallback, hideCreatePrinterModalCallback]);

    React.useEffect(() => {
        getAllPrintersCallback();
    }, [getAllPrintersCallback]);

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Stack direction="row">
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h5">Printer Setup</Typography>
                </Box>
                <CreatePrinterButton
                    onClickAddPrinterCallback={showCreatePrinterModalCallback}
                    />
                <GenericModal
                    showModal={showCreatePrinterModal}
                    hideModalCallback={hideCreatePrinterModalCallback}
                    dialogTitle="Create Printer"
                    >
                    <CreatePrinterForm
                        clickSubmitCallback={submitCreatePrinterModalCallback}
                        />
                </GenericModal>
            </Stack>
            <PrinterSetupDataGrid
                rows={printers}
                reloadDataCallback={getAllPrintersCallback}
                />
        </Stack>
);
}

export { PrinterSetup }