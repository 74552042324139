import React, { useImperativeHandle } from "react";
import { PartDetails } from "../../../types/dsp/print";
import { PrintableTemplateHandle } from "../../../types/print/PrintableTemplateHandle";
import { BinLabelTemplate } from "./binLabel/binLabelTemplate"
import { StockLabelTemplate } from "./stockLabel/stockLabelTemplate";
import { VinLabelTemplate } from "./vinLabel/vinLabelTemplate";
import { Stock } from "../../../types/dsv/print";
import { Stack, Typography } from "@mui/material";

type PrintTemplateResolverProps = {
    printType: "bin" | "stock" | "vin" | ""
    partDetails: PartDetails[]
    foundStock: Stock[]
}

const PrintTemplateResolver = React.forwardRef<PrintableTemplateHandle, PrintTemplateResolverProps>((props, ref) => {
    const [printTemplate, setPrintTemplate] = React.useState<React.ReactNode>();
    const binLabelTemplateRef = React.useRef<PrintableTemplateHandle>(null);
    const stockLabelTemplateRef = React.useRef<PrintableTemplateHandle>(null);
    const vinLabelTemplateRef = React.useRef<PrintableTemplateHandle>(null);

    useImperativeHandle(ref, () => ({
        print: () => {
            switch (props.printType) {
                case "bin":
                    binLabelTemplateRef.current?.print();
                    break;
                case "stock":
                    stockLabelTemplateRef.current?.print();
                    break;
                case "vin":
                    vinLabelTemplateRef.current?.print();
                    break;
                default:
                    break;
            }
        }
    }));

    React.useEffect(() => {
        setPrintTemplate(() => {
            switch(props.printType) {
                case "bin":
                    return (
                        <BinLabelTemplate
                            ref={binLabelTemplateRef}
                            partDetails={props.partDetails}
                            />
                    );
                case "stock":
                    return (
                        <StockLabelTemplate
                            ref={stockLabelTemplateRef}
                            partDetails={props.partDetails}
                            />
                    );
                case "vin":
                    return (
                        <VinLabelTemplate
                            ref={vinLabelTemplateRef}
                            foundStock={props.foundStock}
                            />
                    );
                default:
                    return (<></>);
            }
        });
    }, [props.printType, props.partDetails, props.foundStock]);

    return (
        <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            >
            <Typography variant="h5">Print Preview</Typography>
            {printTemplate}
        </Stack>
    )
});

export { PrintTemplateResolver }