import { Button } from "@mui/material";

type CreatePrinterButtonProps = {
    onClickAddPrinterCallback: () => void
}

function CreatePrinterButton(props: CreatePrinterButtonProps): JSX.Element {
    const { onClickAddPrinterCallback } = props;

    const onClickAddPrinter = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        onClickAddPrinterCallback();
    }

    return (
        <Button
            color="primary"
            onClick={onClickAddPrinter}
            variant="contained"
            >
            Add Printer
        </Button>
    );
}

export { CreatePrinterButton }