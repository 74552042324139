import React from "react";
import { useApiService } from "../apiServiceProvider";
import { GetUserRolesResponse } from "types/admin";

type AdminContextType = {
    isAdmin: boolean
}

const AdminContext = React.createContext<AdminContextType | undefined>(undefined);

type AdminProviderProps = {
    children: React.ReactNode
}

type AdminProviderStateType = {
    userRoles: string[]
}

function AdminProvider(props: AdminProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [adminProviderState, setAdminProviderState] = React.useState<AdminProviderStateType>({
        userRoles: []
    });

    React.useEffect(() => {
        apiService.admin.getUserRoles()
            .then((response: GetUserRolesResponse) => setAdminProviderState(prevState => {
                return {
                    ...prevState,
                    userRoles: response.roles
                }
            }))
            .catch((err) => console.error("An error occurred while getting user roles", err));
    }, [apiService]);

    return (
        <AdminContext.Provider
            value={{
                isAdmin: adminProviderState.userRoles.includes("Admin"),
            }}
            >
            {props.children}
        </AdminContext.Provider>
    );
}

function useAdminContext(): AdminContextType {
    const context = React.useContext(AdminContext);

    if (!context) {
        throw new Error("useAdminContext must be used within a AdminProvider");
    }

    return context;
}

export { AdminProvider, useAdminContext }