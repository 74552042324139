import React from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton } from "@mui/material";
import { DeleteWarningModal } from "./deleteWarningModal";

type DeleteButtonPropsType = {
    deleteId: number
    deleteAction: (deleteId: number) => Promise<void>
    disabled?: boolean
}

function DeleteButton(props: DeleteButtonPropsType): JSX.Element {
    const { deleteId, deleteAction } = props;
    const [showModal, setShowModal] = React.useState(false);

    const hideModalCallback = React.useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    const confirmDeleteCallback = React.useCallback(() => {
        deleteAction(deleteId)
            .then(() => setShowModal(false))
            .catch((err) => console.error(`Unable to delete item ${deleteId}`, err));
    }, [deleteAction, deleteId])

    const onClickDelete = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        setShowModal(true);
    }

    return (
        <>
            <DeleteWarningModal
                deleteDialog={`Do you want to permanently delete id '${deleteId}'?`}
                showModal={showModal}
                hideModalCallback={hideModalCallback}
                confirmDeleteCallback={confirmDeleteCallback}
                />
            <IconButton
                color="error"
                disabled={props.disabled}
                onClick={onClickDelete}
                >
                <DeleteOutlineOutlinedIcon />
            </IconButton>
        </>
    );
}

export { DeleteButton }