import { DspPrinterTypeDef } from "types/dsp/printerTypes";

class DspPrinterType {
    private static printerTypes: DspPrinterTypeDef[] = [
        { id: 0, value: "Unspecified" },
        { id: 1, value: "Fastmark" },
        { id: 2, value: "S4M" },
        { id: 3, value: "Zebra Mobile" },
        { id: 4, value: "ZPL II" }
    ];

    static getAllTypes(): DspPrinterTypeDef[] {
        return this.printerTypes;
    }

    static map(key: number): DspPrinterTypeDef {
        const foundVal = this.printerTypes.find(def => def.id === key);

        if (!foundVal) {
            throw new Error("Unable to map printer type");
        }

        return foundVal;
    }

    static mapValue(key: number): string {
        try {
            var foundVal = this.map(key);
            return foundVal.value;
        } catch {
            return "Unknown";
        }
    }

    static isValidMapping(key: number): boolean {
        try {
            this.map(key);
            return true;
        } catch {
            return false;
        }
    }
}

export { DspPrinterType }