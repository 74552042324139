import React from "react";
import { useDspAuditVarianceContext } from "../../../../../context";
import { Alert, Typography } from "@mui/material";

function HandwritesWarning(): JSX.Element {
    const { hasHandwrites } = useDspAuditVarianceContext();
    const [component, setComponent] = React.useState(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (hasHandwrites) {
                case true:
                    return (
                        <Alert severity="warning">
                            <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="body2"
                                >
                                You have chosen to override a quantity! This will be noted in the audit report.
                            </Typography>
                        </Alert>
                    );
                case false:
                    return (<></>);
            }
        });
    }, [hasHandwrites]);

    return (component);
}

export { HandwritesWarning }