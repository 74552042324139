import React from "react";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useApiService } from "../../../../../base/providers";
import { DeleteWarningModal } from "../../../../../base/crud/deleteWarningModal";

function CancelAuditButton(): JSX.Element {
    const { auditId } = useParams();
    const navigate = useNavigate();
    const { apiService } = useApiService();
    const [showModal, setShowModal] = React.useState(false);

    const setHideModalCallback = React.useCallback(() => {
        setShowModal(false);
    }, [])

    const confirmDeleteCallback = React.useCallback(() => {
        if (!auditId) {
            return;
        }

        apiService.dsp.cancelAudit(Number(auditId))
            .then(() => setShowModal(false))
            .then(() => navigate("/parts/audit"))
            .catch((err) => console.error("Unable to cancel audit", err));
    }, [apiService, auditId, navigate])

    const onClickCancelAudit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        
        setShowModal(true);
    }

    return (
        <>
            <DeleteWarningModal
                confirmDeleteCallback={confirmDeleteCallback}
                deleteDialog="Are you sure you want to cancel this audit?"
                hideModalCallback={setHideModalCallback}
                showModal={showModal}
                />
            <Button
                color="error"
                onClick={onClickCancelAudit}
                variant="outlined"
                >
                Cancel Audit
            </Button>
        </>
        
    );
}

export { CancelAuditButton }