import React, { useImperativeHandle } from "react";
import { Box } from "@mui/material";
import { PrintableTemplateHandle, PrintableTemplateProps } from "../../../../types/print/PrintableTemplateHandle";
import { VinLabelPage } from "./vinLabelPage";
import { Stock } from "../../../../types/dsv/print";
import { useReactToPrint } from "react-to-print";

type VinLabelTemplateProps = PrintableTemplateProps & {
    foundStock: Stock[]
}

const VinLabelTemplate = React.forwardRef<PrintableTemplateHandle, VinLabelTemplateProps>((props, ref) => {
    const vinLabelTemplateRef = React.useRef<HTMLDivElement>(null);
    const [vinLabelPages, setVinLabelPages] = React.useState<JSX.Element[]>([]);
    const reactToPrintFn = useReactToPrint({ contentRef: vinLabelTemplateRef });

    useImperativeHandle(ref, () => ({
        print: () => {
            if (!vinLabelTemplateRef || !vinLabelTemplateRef.current) {
                return;
            }

            reactToPrintFn();
        }
    }));

    React.useEffect(() => {
        setVinLabelPages(props.foundStock.map((stock: Stock, index: number): JSX.Element => {
            return (
                <VinLabelPage
                    key={index}
                    stock={stock}
                    />
            );
        }));
    }, [props.foundStock])

    return (
        <Box
            ref={vinLabelTemplateRef}
            id="label-container"
            >
            {vinLabelPages}
        </Box>
    );
});

export { VinLabelTemplate }