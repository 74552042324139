import React from "react";
import { GenericDataGrid } from "../../../../../base/dataGrid";
import { DspAuditBinSimple, DspAuditVariance } from "types/dsp/audits";
import { useDspAuditVarianceContext } from "../../context";
import { columns } from "./columns";
import { VarianceFlags } from "./columns/types";

type MappedRow = {
    id: number
    partNumber: string
    bins: DspAuditBinSimple[]
    vendor: string
    dollarVariance: number
    quantityVariance: number
    scannedCount: number
    scannedBy: string
    scanDate: Date
    partsMovement: number
    isSelected: boolean
    newQty: number
    onHandCount: number
    varianceFlags: VarianceFlags
}

function PendingVarianceDataGrid(): JSX.Element {
    const { auditVariances, selectedPending, onChangeIsPendingVarianceSelected } = useDspAuditVarianceContext();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        if (!auditVariances) {
            return;
        }

        setMappedRows(auditVariances.map((auditVariance: DspAuditVariance) => {
            return {
                id: auditVariance.lightyearPartId,
                partNumber: auditVariance.partNumber,
                bins: auditVariance.bins,
                vendor: auditVariance.vendor,
                dollarVariance: auditVariance.dollarVariance,
                quantityVariance: auditVariance.quantityVariance,
                scannedCount: auditVariance.scannedCount,
                scannedBy: auditVariance.auditorName,
                scanDate: auditVariance.auditDate,
                partsMovement: auditVariance.movementSinceScan,
                isSelected: false,
                newQty: auditVariance.scannedCount,
                onHandCount: auditVariance.onHandCount,
                varianceFlags: {
                    onHoldCount: auditVariance.onHoldCount,
                    openOrderCount: auditVariance.openOrderCount,
                    hasMultipleBinsAssigned: auditVariance.hasMultipleBinsAssigned,
                    hasSupersession: auditVariance.hasSupersessions,
                },
            };
        }));
    }, [auditVariances]);

    return (
        <GenericDataGrid
            columns={columns({
                selectedRows: selectedPending,
                onChangeIsRowSelectedCallback: onChangeIsPendingVarianceSelected
            })}
            columnVisibilityModel={{
                id: false,
                scannedBy: false,
                scanDate: false
            }}
            rows={mappedRows}
            />
    );
}

export { PendingVarianceDataGrid }