import React from "react";
import { Button, Stack, TextField } from "@mui/material";

type PrintVinLabelsFormValues = {
    selectedPrinterIdStr: string
    stockNumbersStr: string
    labelCount: number
}

type PrintFormProps = {
    clickSubmitCallback: (formValues: PrintVinLabelsFormValues) => void
}

function PrintForm(props: PrintFormProps): JSX.Element {
    const { clickSubmitCallback } = props;
    const [formValues, setFormValues] = React.useState<PrintVinLabelsFormValues>({
        selectedPrinterIdStr: "",
        stockNumbersStr: "",
        labelCount: 1
    });

    const onChangeStockNumbers = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        
        setFormValues(prevState => ({
            ...prevState,
            stockNumbersStr: e.target.value || ""
        }));
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        clickSubmitCallback(formValues);
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            sx={{
                paddingTop: 2
            }}
            >        
            <TextField
                id="stockNumbers"
                label="Stock Number(s)"
                multiline={true}
                onChange={onChangeStockNumbers}
                placeholder="Example: A77533, R31101"
                rows={4}
                value={formValues.stockNumbersStr}
                />
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="contained"
                >
                Print Label(s)
            </Button>
        </Stack>
    );
}

export { PrintForm }
export type { PrintVinLabelsFormValues }