import React from "react";
import { GenericDataGrid } from "../../../../base/dataGrid";
import { DspAuditBin } from "types/dsp/audits";
import { useDspAuditSelectionContext } from "../context";
import { columns } from "./columns";

type MappedRow = DspAuditBin & {
    id: number
    lastAuditDate?: Date | null
    isSelected: boolean
}

function BinSelectionDataGrid(): JSX.Element {
    const { isReadOnly, selectableBins, selectedBins, changeIsRowSelected } = useDspAuditSelectionContext();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        if (!selectableBins || selectableBins.length === 0) {
            return;
        }

        setMappedRows(selectableBins
            .filter((dspAuditBin: DspAuditBin) => !isReadOnly || selectedBins.has(dspAuditBin.binName))
            .map((dspAuditBin: DspAuditBin, index: number) => {
                return {
                    id: index,
                    binName: dspAuditBin.binName,
                    binStatus: dspAuditBin.binStatus,
                    uniqueParts: dspAuditBin.uniqueParts,
                    totalParts: dspAuditBin.totalParts,
                    binDollarValue: dspAuditBin.binDollarValue,
                    createdDate: dspAuditBin.createdDate,
                    submittedDate: dspAuditBin.submittedDate,
                    lastAuditDate: dspAuditBin.lastAuditDate,
                    isSelected: false
                };
            }));
    }, [selectableBins, isReadOnly, selectedBins]);

    return (
        <GenericDataGrid
            columns={columns({
                isReadOnly: isReadOnly,
                selectedRows: selectedBins,
                onChangeIsRowSelected: changeIsRowSelected
            })}
            columnVisibilityModel={{
                id: false,
                auditor: false
            }}
            initialStatePagination={{
                page: 0,
                pageSize: 10
            }}
            initialStateSorting={{
                sortModel: [{ field: "binName", sort: "asc" }]
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            rows={mappedRows}
            />
    );
}

export { BinSelectionDataGrid }