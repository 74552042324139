import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { CancelAuditButton, FinishAuditButton, ViewVarianceButton } from "../../../buttons";
import { useDspAuditDetailsContext } from "../../../context";

function AuditDetailsTitleRowView(): JSX.Element {
    const { auditName } = useDspAuditDetailsContext();

    return (
        <Stack
            direction="row"
            spacing={2}
            >
            <Typography
                variant="h6"
                sx={{
                    flex: 1
                }}
                >
                {auditName}
            </Typography>
            <CancelAuditButton />
            <FinishAuditButton />
            <ViewVarianceButton />
        </Stack>
    );
}

export { AuditDetailsTitleRowView }