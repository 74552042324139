import { Grid } from "@mui/material";
import {
    BinsCard,
    NetDollarVarianceCard,
    NetQuantityVarianceCard,
    OverallVarianceCard,
    TotalPartsCard,
    TotalPartValueCard
} from "./cards";

function CardRow(): JSX.Element {
    return (
        <Grid
            container={true}
            spacing={2}
            >
            <BinsCard />
            <TotalPartsCard />
            <TotalPartValueCard />
            <NetDollarVarianceCard />
            <NetQuantityVarianceCard />
            <OverallVarianceCard />
        </Grid>
    );
}

export { CardRow }