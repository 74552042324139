import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";

type DeleteWarningModalProps = {
    showModal: boolean
    deleteDialog: string
    hideModalCallback: () => void
    confirmDeleteCallback: () => void
}

function DeleteWarningModal(props: DeleteWarningModalProps): JSX.Element {
    const onClickCancel = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        props.hideModalCallback();
    }

    const onClickConfirm = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        props.confirmDeleteCallback();
    }

    return(
        <Dialog
            open={props.showModal}
            >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>{props.deleteDialog}</DialogContent>
            <DialogActions>
                <Button
                    autoFocus={true}
                    color="info"
                    onClick={onClickCancel}
                    >
                    Cancel
                </Button>
                <Button
                    color="error"
                    onClick={onClickConfirm}
                    variant="contained"
                    >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export { DeleteWarningModal }