import { Chip } from "@mui/material";
import React from "react";

type FilterOptionChipProps = {
    count?: number
}

function FilterOptionChip(props: FilterOptionChipProps): JSX.Element {
    const { count } = props;
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            if (!count) {
                return <></>;
            } else {
                return (
                    <Chip
                        label={count}
                        size="small"
                        sx={{
                            marginLeft: 1
                        }}
                        />
                )
            }
        })
    }, [count]);

    return (component);
}

export { FilterOptionChip }