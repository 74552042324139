import { BaseOption } from "./BaseOption";

function PartOption(): JSX.Element {
    return (
        <BaseOption
            name="Parts"
            description="Web application for Dealer Scan - Parts"
            routeTarget="/parts"
            />
    );
}

export { PartOption }