import { Checkbox } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DspAuditBinSimple } from "types/dsp/audits";
import { Utilities } from "../../../../../../../utilities";
import { NewQuantityInput } from "../newQuantityInput";
import { FlagsColumn } from "./flags";

type ColumnParamsType = {
    selectedRows: Set<number>
    onChangeIsRowSelectedCallback: (lightyearId: number) => void
}

function columns(columnParams: ColumnParamsType): GridColDef[] {
    return [
        {
            field: "id",
            flex: 1,
            headerName: "ID",
            hideable: true
        },
        {
            field: "isSelected",
            flex: 0.5,
            headerName: "Select",
            hideable: false,
            renderCell: (params) => (
                <Checkbox
                    checked={columnParams.selectedRows.has(params.row.id)}
                    onChange={() => columnParams.onChangeIsRowSelectedCallback(params.row.id)}
                    />
            )
        },
        {
            field: "partNumber",
            flex: 1,
            headerName: "Part Number",
            hideable: false
        },
        {
            field: "bins",
            flex: 0.5,
            headerName: "Bin",
            hideable: false,
            valueFormatter: (value: DspAuditBinSimple[]) => value.map((bin: DspAuditBinSimple) => bin.binName).join(", ")
        },
        {
            field: "vendor",
            flex: 0.5,
            headerName: "Vendor",
            hideable: false
        },
        {
            field: "onHandCount",
            flex: 0.75,
            headerName: "On Hand Count",
            hideable: false
        },
        {
            field: "dollarVariance",
            flex: 0.75,
            headerName: "$ Variance",
            hideable: false,
            valueFormatter: (value: number) => Utilities.displayAsCurrency(value)
        },
        {

            field: "quantityVariance",
            flex: 0.75,
            headerName: "Qty Variance",
            hideable: false
        },
        {
            field: "scannedCount",
            flex: 0.5,
            headerName: "Scanned",
            hideable: false
        },
        {
            field: "scannedBy",
            flex: 1,
            headerName: "Scanned By",
            hideable: true
        },
        {
            field: "scanDate",
            flex: 1,
            headerName: "Scan Date",
            hideable: true
        },
        {
            field: "partsMovement",
            flex: 0.5,
            headerName: "Movement",
            hideable: false
        },
        {
            field: "newQty",
            flex: 0.75,
            headerName: "New Qty",
            hideable: false,
            renderCell: (params) => (<NewQuantityInput lightyearId={params.row.id} />)
        },
        {
            field: "varianceFlags",
            flex: 2,
            headerName: "",
            hideable: false,
            renderCell: (params) => (<FlagsColumn varianceFlags={params.value} />)
        }
    ];
}

export { columns }