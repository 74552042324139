import React from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from "@mui/material";

type EditButtonPropsType = {
    editId: number
    editAction: (editId: number) => void
}

function EditButton(props: EditButtonPropsType): JSX.Element {
    const { editId, editAction } = props;

    const onClickEdit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        editAction(editId);
    }

    return (
        <IconButton
            color="primary"
            onClick={onClickEdit}
            >
            <EditOutlinedIcon />
        </IconButton>
    );
}

export { EditButton }