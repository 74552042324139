import React, { useImperativeHandle } from "react";
import { Snackbar } from "@mui/material";
import { SnackbarFeedbackHandle, SnackbarFeedbackProps } from "types/snackbar";

type SnackbarStateType = {
    isOpen: boolean
    message: string
}

const SnackbarFeedback = React.forwardRef<SnackbarFeedbackHandle, SnackbarFeedbackProps>((props, ref) => {
    const [snackbarState, setSnackbarState] = React.useState<SnackbarStateType>({
        isOpen: false,
        message: ""
    });

    useImperativeHandle(ref, () => ({
        setMessage: (message: string) => {
            setSnackbarState(prevState => {
                return {
                    ...prevState,
                    message: message
                }
            });
        },
        setIsOpen: (isOpen: boolean) => {
            setSnackbarState(prevState => {
                return {
                    ...prevState,
                    isOpen: isOpen
                }
            });
        }
    }));

    const onCloseSnackbar = (): void => {
        setSnackbarState(prevState => {
            return {
                ...prevState,
                isOpen: false
            };
        });
    }

    return (
        <Snackbar
            anchorOrigin={{
                horizontal: "center",
                vertical: "top"
            }}
            autoHideDuration={5000}
            message={snackbarState.message}
            onClose={onCloseSnackbar}
            open={snackbarState.isOpen}
            />
    )
});

export { SnackbarFeedback }