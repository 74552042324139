import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

type GenericModalProps = {
    children: JSX.Element
    dialogTitle: string
    hideModalCallback: () => void
    showModal: boolean
    confirmActionText?: string
    confirmActionCallback?: () => void
    showConfirmAction?: boolean
}

function GenericModal(props: GenericModalProps): JSX.Element {
    const { children, dialogTitle, hideModalCallback, showModal } = props;

    const onClose = (e: {}, reason: "backdropClick" | "escapeKeyDown" ): void => {
        hideModalCallback();
    }

    const onClickCancel = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        hideModalCallback();
    }
    
    return (
        <Dialog
            open={showModal}
            onClose={onClose}
            >
            <DialogTitle>
                <Stack direction="row">
                    <Typography
                        variant="h6"
                        sx={{ flexGrow: 1 }}
                        >
                        {dialogTitle}
                    </Typography>
                    <IconButton onClick={onClickCancel}>
                        <CloseIcon />
                    </IconButton>
                </Stack></DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    autoFocus={true}
                    color="info"
                    onClick={onClickCancel}
                    >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={props.confirmActionCallback}
                    sx={{
                        display: props.showConfirmAction
                            ? null
                            : 'none'
                    }}
                    variant="contained"
                    >
                    {props.confirmActionText || ""}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export { GenericModal }