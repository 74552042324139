import React from "react";
import { AuditsDataGrid } from "../dataGrid";
import { useDspAuditContext } from "../context";
import { NoExistingAuditsView } from "./noExistingAuditsView";

function OngoingAuditsResolver(): JSX.Element {
    const { audits } = useDspAuditContext();
    const [component, setComponent] = React.useState<JSX.Element>(<NoExistingAuditsView />);

    React.useEffect(() => {
        setComponent(() => {
            if (!audits || audits.length === 0) {
                return <NoExistingAuditsView />
            }

            return <AuditsDataGrid />
        })
    }, [audits]);

    return (component);
}

export { OngoingAuditsResolver }