import React from "react";
import { useAuth } from "../authProvider";
import { ApiServiceContainer } from "../../../../services";

type ApiServiceContextType = {
    apiService: ApiServiceContainer
}

const ApiServiceContext = React.createContext<ApiServiceContextType | undefined>(undefined);

type ApiServiceProviderProps = {
    children: React.ReactNode
}

function ApiServiceProvider(props: ApiServiceProviderProps): JSX.Element {
    const { children } = props;
    const { user } = useAuth();
    const [apiService, setApiService] = React.useState<ApiServiceContainer>(ApiServiceContainer.initialInstance);

    React.useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_URL;

        if (!baseUrl || baseUrl.length === 0) {
            console.error("Api Base URL not set");
            setApiService(ApiServiceContainer.initialInstance);
            return;
        }

        if (!user) {
            console.error("Unable to initialize api service");
            setApiService(ApiServiceContainer.initialInstance);
            return;
        }
        
        const { dealerId, locationId, username, accessToken } = user;
        setApiService(new ApiServiceContainer(baseUrl, dealerId, locationId, username, accessToken));
    }, [setApiService, user]);
    
    return (
        <ApiServiceContext.Provider
            value={{
                apiService
            }}
            >
            {children}
        </ApiServiceContext.Provider>
    );
}

function useApiService(): ApiServiceContextType {
    const context = React.useContext(ApiServiceContext);

    if (!context) {
        throw new Error("useApiService must be used within an ApiServiceProvider.");
    }

    return context;
}

export { ApiServiceProvider, useApiService };