import React from "react";
import { TextField } from "@mui/material";
import { ResetButton } from "./resetButton";
import { useDspAuditVarianceContext } from "../../../context";

type NewQuantityInputProps = {
    lightyearId: number
}

function NewQuantityInput({ lightyearId }: NewQuantityInputProps): JSX.Element {
    const { getQuantity, setNewQuantity } = useDspAuditVarianceContext();
    const [displayQuantity, setDisplayQuantity] = React.useState(0);

    const onChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setNewQuantity(lightyearId, e.target.valueAsNumber);
    }

    React.useEffect(() => {
        setDisplayQuantity(() => {
            return getQuantity(lightyearId);
        });
    }, [lightyearId, getQuantity])

    return (
        <TextField
            InputProps={{
                endAdornment: (<ResetButton lightyearId={lightyearId} />),
                inputProps: {
                    min: 0
                }
            }}
            onChange={onChangeQuantity}
            type="number"
            value={displayQuantity}
            />
        );
}

export { NewQuantityInput }