import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

type BaseOptionProps = {
    name: string
    description: string
    routeTarget: string
}

function BaseOption(props: BaseOptionProps): JSX.Element {
    const { name, description, routeTarget } = props;
    const navigate = useNavigate();

    const onClickGoTo = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate(routeTarget);
    }
    
    return (
        <Box
            sx={{
                paddingLeft: 2,
                paddingRight: 10,
                paddingY: 2,
                border: "1px solid grey"
            }}
            >
            <Stack
                direction="column"
                spacing={2}
                >
                <Typography variant="h6">{name}</Typography>
                <Typography variant="body1">{description}</Typography>
                <Stack
                    direction="row"
                    spacing={2}
                    >
                    <Button
                        onClick={onClickGoTo}
                        variant="contained"
                        >
                        Go to {name}
                    </Button>
                    <Button
                        variant="contained"
                        >
                        Make {name} Default
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}

export { BaseOption }