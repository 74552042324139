import React from "react";
import { BaseCard } from "../base";
import { useDspAuditDetailsContext } from "../../../../../context";
import { DspAuditVarianceReport } from "types/dsp/audits";

function NetDollarVarianceCard(): JSX.Element {
    const { auditVariances } = useDspAuditDetailsContext();
    const [displayValue, setDisplayValue] = React.useState(0);

    React.useEffect(() => {
        const netDollarVariance = auditVariances.map((value: DspAuditVarianceReport) => {
                return value.newQuantity
                    ? (value.newQuantity - value.expected) * value.partCost
                    : (value.scanned - value.expected) * value.partCost;
            })
            .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
            
        setDisplayValue(netDollarVariance);
    }, [auditVariances]);
    
    return (
        <BaseCard
            displayValueAs="currency"
            title="Net Dollar Variance"
            value={displayValue}
            useAccountingColors={true}
            />
    );
}

export { NetDollarVarianceCard }