import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { useApiService } from "../../../base/providers";
import { AuditLocation } from "../../../../types/dsv/setup";
import { LocationsDropdown } from "./locationsDropdown";

type EditLotFormValues = {
    id: number
    locationId: string
    lotName: string
}

type EditLotFormProps = {
    id: number
    clickSubmitCallback: (formValues: EditLotFormValues) => void
}

function EditLotForm(props: EditLotFormProps): JSX.Element {
    const { clickSubmitCallback } = props;
    const { apiService } = useApiService();
    const [formValues, setFormValues] = React.useState<EditLotFormValues>({
        id: -1,
        locationId: "",
        lotName: ""
    });

    const setSelectedLocationCallback = React.useCallback((selectedLocation: string) => {
        setFormValues(prevState => ({
            ...prevState,
            locationId: selectedLocation
        }));
    }, []);

    React.useEffect(() => {
        const { id } = props;

        apiService.dsv.getAuditLocationById(id)
            .then((auditLocation: AuditLocation) => {
                setFormValues({
                    id: auditLocation.auditLocationId,
                    locationId: auditLocation.lySetupLocationId.toString(),
                    lotName: auditLocation.locationName,
                });
            })
            .catch((err: any) => console.error(`Unable to get audit location with id ${id}`, err));
    }, [props, setFormValues, apiService]);

    const onChangeLotName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        setFormValues(prevState => ({
            ...prevState,
            lotName: e.target.value || ""
        }));
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        clickSubmitCallback(formValues);
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            sx={{
                paddingTop: 2
            }}
            >
            <LocationsDropdown
                selectedLocation={formValues.locationId}
                setSelectedLocationCallback={setSelectedLocationCallback}
                />
            <TextField
                id="lotName"
                label="Lot Name"
                onChange={onChangeLotName}
                value={formValues.lotName}
                />
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="contained"
                >
                Save
            </Button>
        </Stack>
    );
}

export { EditLotForm }
export type { EditLotFormValues }