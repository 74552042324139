import { createBrowserRouter } from "react-router-dom"

import { AdminView } from "./components/admin";

import { Layout } from "./components/base";

import { PartsRoutes } from "./components/dsp";
import {
    AuditsView,
    AuditDetailsView,
    BinSelectionView,
    VarianceView
} from "./components/dsp/audit";
import { DspPrintView } from "./components/dsp/print";
import { Setup } from "./components/dsp/setup";

import { VehicleRoutes } from "./components/dsv";
import { AuditDetails } from "./components/dsv/audit";
import { AllVehicleAudits } from "./components/dsv/home/allVehicleAudits";
import { DsvPrintView } from "./components/dsv/print";
import { CombinedSetup } from "./components/dsv/setup";

import { Options } from "./components/options";

const appRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "",
                element: <Options />,
            },
            {
                path: "/admin",
                element: <AdminView />
            },
            {
                path: "/parts",
                element: <PartsRoutes />,
                children: [
                    {
                        path: "/parts",
                        element: <AuditsView />
                    },
                    {
                        path: "/parts/print",
                        element: <DspPrintView />
                    },
                    {
                        path: "/parts/audit",
                        element: <AuditsView />
                    },
                    {
                        path: "/parts/audit/:auditId",
                        element: <AuditDetailsView />
                    },
                    {
                        path: "/parts/audit/selection/:auditId",
                        element: <BinSelectionView />
                    },
                    {
                        path: "/parts/audit/:auditId/variance",
                        element: <VarianceView />
                    },
                    {
                        path: "/parts/setup",
                        element: <Setup />
                    }
                ]
            },
            {
                path: "/vehicles",
                element: <VehicleRoutes />,
                children: [
                    {
                        path: "/vehicles",
                        element: <AllVehicleAudits />
                    },
                    {
                        path: "/vehicles/audits",
                        element: <AllVehicleAudits />
                    },
                    {
                        path: "/vehicles/audits/:auditId",
                        element: <AuditDetails />
                    },
                    {
                        path: "/vehicles/print",
                        element: <DsvPrintView />
                    },
                    {
                        path: "/vehicles/setup",
                        element: <CombinedSetup />
                    }
                ]
            }
        ]
    }
]);

export { appRouter };