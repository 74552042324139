import { Stack, Typography } from "@mui/material";
import { PartOption } from "./PartsOption";
import { VehicleOption } from "./VehiclesOption";

function Options(): JSX.Element {
    return (
        <Stack
            direction="column"
            spacing={5}
            >
            <Typography variant="h6">Select an Option</Typography>
            <Stack
                direction="row"
                spacing={5}
                >
                <PartOption />
                <VehicleOption />
            </Stack>
        </Stack>
    );
}

export { Options }