import { PreDefinedFilterModel } from "../../../../../base/dataGrid";

const predefinedFilters: PreDefinedFilterModel[] = [
    {
        label: "Open Audits",
        filterModel: {
            items: [
                { id: 1, field: "type", operator: "equals", value: "open" }
            ]
        }
    },
    {
        label: "Closed Audits",
        filterModel:{
            items: [
                { id: 2, field: "type", operator: "equals", value: "finished" }
            ]
        }
    }
];

export { predefinedFilters }