import { Tooltip, Button } from "@mui/material";

function FinishAuditDisabledButton(): JSX.Element {
    return (
        <Tooltip
            placement="top"
            title="Cannot finish audit until all bins have been reconciled"
            >
            <Button
                color="primary"
                variant="outlined"
                >
                Finish Audit
            </Button>
        </Tooltip>
    );
}

export { FinishAuditDisabledButton }