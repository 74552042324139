import React from "react";
import { Button } from "@mui/material";
import { SnackbarFeedback } from "../../../../../../base/feedback/snackbar";
import { useDspAuditDetailsContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import { FinishAuditResponse } from "types/dsp/audits";
import { SnackbarFeedbackHandle } from "types/snackbar";

function FinishAuditEnabledButton(): JSX.Element {
    const { finishAudit } = useDspAuditDetailsContext();
    const navigate = useNavigate();
    const snackbarFeedbackRef = React.useRef<SnackbarFeedbackHandle>(null);

    const onClickFinishAudit = (): void => {
        finishAudit()
            .then((response: FinishAuditResponse) => {
                if (!snackbarFeedbackRef.current) {
                    return;
                }

                if (response.success) {
                    navigate("/parts");
                } else {
                    snackbarFeedbackRef.current.setMessage(`Unable to finish audit. ${response.unfinishedBins.length} unfinished bins in audit.`);
                    snackbarFeedbackRef.current.setIsOpen(true);
                }
            })
            .catch((err) => {
                console.error("Unable to finish audit", err);
            });
    }

    return (
        <>
            <Button
                color="primary"
                onClick={onClickFinishAudit}
                variant="contained"
                >
                Finish Audit
            </Button>
            <SnackbarFeedback ref={snackbarFeedbackRef} />
        </>
    );
}

export { FinishAuditEnabledButton }