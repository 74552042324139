import { Box, Stack, Typography } from "@mui/material";
import { CreateAuditButton } from "../../buttons";

function NoExistingAuditsView(): JSX.Element {
    return (
        <Stack
            alignItems="center"
            component={Box}
            direction="column"
            spacing={2}
            >
            <Typography
                variant="h6"
                >
                Start your first Audit
            </Typography>
            <Typography
                variant="body2"
                >
                Info about what to expect and why to use the audit feature
            </Typography>
            <CreateAuditButton />
        </Stack>
    );
}

export { NoExistingAuditsView }