import React from "react";
import { useApiService } from "../../base/providers";
import { StoreOption } from "../../../types/auth/locations";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

type LocationSelectionDropdownProps = {
    selectedStoreOptionIds: string[]
    setSelectedStoreOptionIds: (storeOptionIds: string[]) => void
}

function LocationSelectionDropdown(props: LocationSelectionDropdownProps): JSX.Element {
    const { selectedStoreOptionIds, setSelectedStoreOptionIds } = props;
    const { apiService } = useApiService();
    const [storeOptions, setStoreOptions] = React.useState<StoreOption[]>([]);
    const [optionsElements, setOptionsElements] = React.useState<JSX.Element[]>([]);

    React.useEffect(() => {
        apiService.dsv.getStoreOptions()
            .then((storeOptionsArr: StoreOption[]) => {
                setStoreOptions(storeOptionsArr);
            })
            .catch((err) => console.error("Unable to get store options", err));
    }, [apiService]);

    React.useEffect(() => {
        if (!storeOptions || storeOptions.length === 0) {
            return;
        }
        
        const locationIdsStrArr: string[] = storeOptions.map((storeOption: StoreOption) => `${storeOption.lySetupLocationId}`);
        setSelectedStoreOptionIds(locationIdsStrArr);
    }, [storeOptions, setSelectedStoreOptionIds]);

    React.useEffect(() => {
        if (!storeOptions) {
            return;
        }

        setOptionsElements(storeOptions.map((storeOption) => (
            <MenuItem key={storeOption.lySetupLocationId} value={`${storeOption.lySetupLocationId}`}>
                {storeOption.lySetupLocationId} - {storeOption.cocoName}
            </MenuItem>
        )));
    }, [storeOptions]);

    const onChangeSelectedValue = (e: SelectChangeEvent<string[]>): void => {
        e.preventDefault();

        const storeIdsArr = e.target.value as string[];
        if (!storeIdsArr) {
            return;
        }
        
        setSelectedStoreOptionIds(storeIdsArr);
    }
    
    return (
        <FormControl>
            <InputLabel id="location-selection-dropdown-label">Select Location</InputLabel>
            <Select
                id="location"
                labelId="location-selection-dropdown-label"
                label="Select Location"
                multiple={true}
                onChange={onChangeSelectedValue}
                renderValue={(selectedOptions) => (<span>{selectedOptions.length} Selected Locations</span>)}
                value={selectedStoreOptionIds}
                >
                {optionsElements}
            </Select>
        </FormControl>
    );
};

export {
    LocationSelectionDropdown
}