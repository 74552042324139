import { Stack } from "@mui/material";
import { VarianceOptionsRow } from "./optionsRow";
import { VarianceTitleRow } from "./titleRow";
import { VarianceDataGridResolver } from "./dataGrid";
import { DspAuditVarianceProvider } from "./context";

function VarianceView(): JSX.Element {
    return (
        <DspAuditVarianceProvider>
            <Stack
                direction="column"
                spacing={2}
                >
                <VarianceTitleRow />
                <VarianceOptionsRow />
                <VarianceDataGridResolver />
            </Stack>
        </DspAuditVarianceProvider>
        
    );
}

export { VarianceView }