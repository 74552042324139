import { Box, Stack, Typography } from "@mui/material";
import Barcode from "react-barcode";

type BinLabelPageProps = {
    bin: string
}

function BinLabelPage(props: BinLabelPageProps): JSX.Element {
    return (
        <Stack
            className="label"
            direction="column"
            useFlexGap={true}
            >
            <Box className="binLabelBarcode">
                <Barcode
                    displayValue={false}
                    height={50}
                    renderer="svg"
                    value={props.bin}
                    width={1.8}
                    />
            </Box>
            <Typography
                className="binLabelText"
                >
                {props.bin}
            </Typography>
        </Stack>
    );
}

export { BinLabelPage }