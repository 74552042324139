import { Box, Stack, Typography } from "@mui/material";
import Barcode from "react-barcode";

type StockLabelPageProps = {
    bin: string
    description: string
    partNumber: string
}

function StockLabelPage(props: StockLabelPageProps): JSX.Element {
    return (
        <Stack
            className="label"
            direction="column"
            useFlexGap={true}
            >
            <Box className="stockLabelTopRow">
                <Typography
                    className="stockLabelPartNumberText"
                    >
                    {props.partNumber}
                </Typography>
                <Typography className="stockLabelBinText">
                    {props.bin}
                </Typography>
            </Box>
            <Box className="stockLabelBarcode">
                <Barcode
                    displayValue={false}
                    height={35}
                    renderer="svg"
                    value={props.partNumber}
                    width={1.25}
                    />
            </Box>
            <Typography className="stockLabelDescriptionText">
                {props.description}
            </Typography>
        </Stack>
    );
}

export { StockLabelPage }