import { Grid } from "@mui/material";

type CenteredFormViewProps = {
    children: JSX.Element
}

function CenteredFormView(props: CenteredFormViewProps): JSX.Element {
    return (
        <Grid container>
            <Grid item sm={0} md={4} />
            <Grid item sm={12} md={4}>
                {props.children}
            </Grid>
            <Grid item sm={0} md={4} />
        </Grid>
    );
}

export { CenteredFormView }