import { DspAuditDetailsProvider } from "./context";
import { ViewResolver } from "./resolver/view";

function AuditDetailsView(): JSX.Element {
    return (
        <DspAuditDetailsProvider>
            <ViewResolver />
        </DspAuditDetailsProvider>
    );
}

export { AuditDetailsView }