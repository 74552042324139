import { Button, Chip } from "@mui/material";
import { useDspAuditVarianceContext } from "../../context";

function PendingTabButton() {
    const { auditVariances, setAuditVarianceType } = useDspAuditVarianceContext();
    const onClickPendingTabButton = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        setAuditVarianceType("pending");
    }

    return (
        <Button onClick={onClickPendingTabButton}>
            Pending Variances&nbsp;
            <Chip
                color="primary"
                label={auditVariances.length}
                />
        </Button>
    );
}

export { PendingTabButton }