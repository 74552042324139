import { useNavigate } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import { useDspAuditVarianceContext } from "../context";

function VarianceTitleRow(): JSX.Element {
    const { auditId, auditName } = useDspAuditVarianceContext();
    const navigate = useNavigate();

    const onClickBackToAuditDetails = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate(`/parts/audit/${auditId}`);
    }

    return (
        <Stack
            direction="column"
            >
            <Button
                onClick={onClickBackToAuditDetails}
                variant="text"
                sx={{
                    alignSelf: "start",
                    minWidth: "auto",
                    width: "fit-content"
                }}
                >
                &lt;&nbsp;Back to Audit Details
            </Button>
            <Typography variant="h6">
                {auditName} Variance
            </Typography>
        </Stack>
    );
}

export { VarianceTitleRow }