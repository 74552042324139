import { AxiosInstance } from "axios";
import { GetLocationsResponse } from "../types/auth/locations";

class ApiService {
    protected api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this.api = api;
    }

    getLocations(userName: string): Promise<GetLocationsResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get(`/auth/getlocations?username=${userName}`);
                const getLocationsResponse = response.data as GetLocationsResponse;
                resolve(getLocationsResponse);
            } catch (err) {
                reject(err);
            }
        })
    }
}

export { ApiService }