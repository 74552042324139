import { GenericModal } from "../../../../../../base/modal";
import { ModalCopy } from "./copy";

type PendingVarianceModalProps = {
    showModal: boolean
    hideModalCallback: () => void
    onClickConfirmCallback: () => void
}

function PendingVarianceModal(props: PendingVarianceModalProps): JSX.Element {
    return (
        <GenericModal
            showModal={props.showModal}
            hideModalCallback={props.hideModalCallback}
            dialogTitle="Save to LightYear"
            showConfirmAction={true}
            confirmActionText="Save to LightYear"
            confirmActionCallback={props.onClickConfirmCallback}
            >
            <ModalCopy />
        </GenericModal>
    );
}

export { PendingVarianceModal }