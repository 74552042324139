import React, { useImperativeHandle } from "react";
import { Box } from "@mui/material";
import { PrintableTemplateHandle, PrintableTemplateProps } from "../../../../types/print/PrintableTemplateHandle";
import { BinLabelPage } from "./binLabelPage";
import { PartDetails } from "../../../../types/dsp/print";
import { useReactToPrint } from "react-to-print";

type BinLabelTemplateProps = PrintableTemplateProps & {
    partDetails: PartDetails[]
}

const BinLabelTemplate = React.forwardRef<PrintableTemplateHandle, BinLabelTemplateProps>((props, ref) => {
    const binLabelTemplateRef = React.useRef<HTMLDivElement>(null);
    const [binLabelPages, setBinLabelPages] = React.useState<JSX.Element[]>([]);
    const reactToPrintFn = useReactToPrint({ contentRef: binLabelTemplateRef });

    useImperativeHandle(ref, () => ({
        print: () => {
            if (!binLabelTemplateRef || !binLabelTemplateRef.current) {
                return;
            }

            reactToPrintFn();
        }
    }));

    React.useEffect(() => {
        setBinLabelPages(props.partDetails.map((part: PartDetails, index: number): JSX.Element => {
            return (
                <BinLabelPage
                    bin={part.binOne}
                    key={index}
                    />
            );
        }));
    }, [props.partDetails]);

    return (
        <Box 
            ref={binLabelTemplateRef}
            id="label-container"
            >
            {binLabelPages}
        </Box>
    );
});

export { BinLabelTemplate }