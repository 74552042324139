import { Box, Stack, Typography } from "@mui/material";
import { Stock } from "../../../../types/dsv/print";
import Barcode from "react-barcode";

type VinLabelPageProps = {
    stock: Stock
}

function VinLabelPage(props: VinLabelPageProps): JSX.Element {
    return (
        <Stack className="labelVin">
            <Typography className="labelVinStockNumber">
                {props.stock.stockNumber}
            </Typography>
            <Box className="labelVinBarcode">
                <Barcode
                    displayValue={false}
                    height={100}
                    renderer="svg"
                    value={ props.stock.vin}
                    width={1.2}
                    />
            </Box>
            <Typography className="labelVinIdentificationNumber">
                {props.stock.vin}
            </Typography>
            <Typography className="labelVinDescription">
                {`${props.stock.year} ${props.stock.make} ${props.stock.model} ${props.stock.bodyStyle} ${props.stock.color}`}
            </Typography>
        </Stack>
    );
}

export { VinLabelPage }