import { BaseOption } from "./BaseOption";

function VehicleOption(): JSX.Element {
    return (
        <BaseOption
            name="Vehicles"
            description="Web application for Dealer Scan - VIN"
            routeTarget="/vehicles"
            />
    );
}

export { VehicleOption }