import React from "react";
import { GenericDataGridFilterable } from "../../../../base/dataGrid";
import { useDspAuditContext } from "../context";
import { columns } from "./columns";
import { predefinedFilters } from "./filters";

type MappedRow = {
    id: number
    auditName: string
    status: number
    totalItems: string
    totalAmount: number
    dateCreated: Date
    deleteId: number
    type: "open" | "finished"
}

function AuditsDataGrid(): JSX.Element {
    const { audits } = useDspAuditContext(); 
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        if (!audits || audits.length === 0) {
            return;
        }
        
        setMappedRows(audits.map((audit) => {
            return {
                id: audit.id,
                auditName: audit.auditName,
                status: audit.status,
                totalItems: `${audit.binCount} Bins, ${audit.partCount} Parts`,
                totalAmount: audit.totalCost,
                dateCreated: audit.createdStamp,
                deleteId: audit.id,
                type: audit.status === 2 || audit.status === 3
                    ? "finished"
                    : "open"
            };
        }))
    }, [audits]);
    
    return (
        <GenericDataGridFilterable
            columns={columns}
            filterModels={predefinedFilters}
            columnVisibilityModel={{
                type: false
            }}
            initialStatePagination={{
                page: 0,
                pageSize: 25
            }}
            initialStateSorting={{
                sortModel: [{ field: "dateCreated", sort: "desc" }]
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            rows={mappedRows}
            />
    );
}

export { AuditsDataGrid }