import React from "react";

type User = {
    dealerId: string
    locationId: string
    locationName: string
    userFullName: string
    accessToken: string
    username: string
}

type AuthContextType = {
    isLoggedIn: boolean
    user: User | undefined
    logIn: (loginDetails: any) => void
    logOut: () => void
}

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
    children: React.ReactNode
}

function AuthProvider(props: AuthProviderProps): JSX.Element {
    const { children } = props;
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [user, setUser] = React.useState<User | undefined>(undefined);

    const setLoggedInCallback = React.useCallback((loginDetails: any): void => {
        setUser(loginDetails);
        localStorage.setItem("scan_web_auth", JSON.stringify(loginDetails));
        setIsLoggedIn(true);
    }, [setIsLoggedIn]);

    const setLoggedOutCallback = React.useCallback((): void => {
        localStorage.removeItem("scan_web_auth");
        setIsLoggedIn(false);
    }, [setIsLoggedIn]);
    
    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                user,
                logIn: setLoggedInCallback,
                logOut: setLoggedOutCallback
            }}
            >
            {children}
        </AuthContext.Provider>
    );
}

function useAuth(): AuthContextType {
    const context = React.useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider.");
    }

    return context;
}

export { AuthProvider, useAuth }
export type { User }
