import React from "react";
import { useDspAuditVarianceContext } from "../../context";
import { Button, Chip } from "@mui/material";
import { PendingVarianceModal } from "./modal";

function AcceptPendingVarianceButton(): JSX.Element {
    const { selectedPending, updateBinVariance } = useDspAuditVarianceContext();
    const [showAcceptNewQuantitiesModal, setShowAcceptNewQuantitiesModal] = React.useState(false);
    
    const hideAcceptNewQuantitiesModalCallback = React.useCallback(() => {
        setShowAcceptNewQuantitiesModal(false);
    }, []);

    const onClickAcceptNewQuantityCallback = React.useCallback(() => {
        updateBinVariance()
            .then(() => hideAcceptNewQuantitiesModalCallback())
            .catch((err) => console.error("Unable to update bin variances", err));
    }, [updateBinVariance, hideAcceptNewQuantitiesModalCallback]);

    const onClickAcceptNewQuantity = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        setShowAcceptNewQuantitiesModal(true);
    }

    return (
        <>
            <PendingVarianceModal
                    showModal={showAcceptNewQuantitiesModal}
                    hideModalCallback={hideAcceptNewQuantitiesModalCallback}
                    onClickConfirmCallback={onClickAcceptNewQuantityCallback}
                    />
            <Button
                onClick={onClickAcceptNewQuantity}
                variant="outlined"
                >
                <Chip label={selectedPending.size} />&nbsp;Save to LightYear
            </Button>
            
        </>
    );
}

export { AcceptPendingVarianceButton }