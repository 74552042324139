import { useNavigate } from "react-router-dom";
import { Button, Stack } from "@mui/material";

type NavButtonsProps = {
    showSetup: boolean
    setupPath: string
    children: React.ReactNode
}

function NavButtons(props: NavButtonsProps): JSX.Element {
    const { children, showSetup, setupPath } = props;
    const navigate = useNavigate();

    const onClickSetup = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate(setupPath);
    }

    return (
        <Stack
            direction="row-reverse"
            spacing={1}
            >
            {showSetup &&
                <Button
                    onClick={onClickSetup}
                    variant="outlined"
                    >
                    Setup
                </Button>
            }
            {children}
        </Stack>
    );
}

export { NavButtons }