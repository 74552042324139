import { AcceptPendingVarianceButton } from "./acceptPendingVarianceButton";
import { OptionsResolver } from "./optionsResolver";
import { PendingTabButton } from "./pendingTabButton";
// import { RescanTabButton } from "./rescanTabButton";
import { Box, Stack } from "@mui/material";

function VarianceOptionsRow(): JSX.Element {
    return (
        <Stack direction="row">
            <Box flex={1}>
                <PendingTabButton key="pending-tab" />
                {/*<RescanTabButton key="rescan-tab" />*/}
            </Box>
            <OptionsResolver />
        </Stack>
    );
}

export { AcceptPendingVarianceButton, VarianceOptionsRow }