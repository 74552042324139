import React from "react";
import { VehicleAudit } from "../../../types/dsv/audit";
import { VehicleAuditsDataGrid } from "./vehicleAuditsDataGrid";
import { useApiService } from "../../base/providers";
import { Stack, Typography } from "@mui/material";
import { PrintVinLabelsButton } from "../print/printVinLabelsButton";
import { NavButtons } from "../../base/navButtons";

function AllVehicleAudits(): JSX.Element {
    const { apiService } = useApiService();
    const [vehicleAudits, setVehicleAudits] = React.useState<VehicleAudit[]>([]);

    const getAllVehicleAuditsCallback = React.useCallback(() => {
        apiService.dsv.getAllVehicleAudits()
            .then(vehicleAudits => setVehicleAudits(vehicleAudits))
            .catch(err => console.error("Unable to get all vehicle audits", err));
    }, [apiService, setVehicleAudits]);
    
    React.useEffect(() => {
        getAllVehicleAuditsCallback();
    }, [getAllVehicleAuditsCallback]);

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Stack direction="row">
                <Typography
                    variant="h6"
                    sx={{
                        flexGrow: 1
                    }}
                    >
                    Vehicle Scan History
                </Typography>
                <NavButtons
                    showSetup={true}
                    setupPath="/vehicles/setup"
                    >
                    <PrintVinLabelsButton />
                </NavButtons>
            </Stack>
            <VehicleAuditsDataGrid
                rows={vehicleAudits}
                getAllVehicleAuditsCallback={getAllVehicleAuditsCallback}
                />
        </Stack>
    );
}

export { AllVehicleAudits };