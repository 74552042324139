import React from "react";
import { useParams } from "react-router-dom";
import { useApiService } from "../../../../base/providers";
import { DspAuditBin, GetDspAuditsResponse, GetDspAuditTargetsResponse } from "types/dsp/audits";

type DspAuditSelectionContextType = {
    auditName: string
    isReadOnly: boolean
    selectableBins: DspAuditBin[]
    selectedBins: Set<string>
    changeIsRowSelected: (binName: string) => void
    deleteAudit: () => Promise<void>
    setAuditBins: () => Promise<void>
    setNewAuditName: (newAuditName: string) => void
    setIsReadOnly: (newValue: boolean) => void
    toggleSelectAllRows: () => void
    updateAuditName: () => Promise<void>
}

const DspAuditSelectionContext = React.createContext<DspAuditSelectionContextType | undefined>(undefined);

type DspAuditSelectionProviderProps = {
    children: React.ReactNode
}

type AuditSelectionStateType = {
    auditName: string
    isReadOnly: boolean
    selectableBins: DspAuditBin[]
    selectedBins: Set<string>
}

function DspAuditSelectionProvider(props: DspAuditSelectionProviderProps): JSX.Element {
    const { auditId } = useParams();
    const { apiService } = useApiService();
    const [auditSelectionState, setAuditSelectionState] = React.useState<AuditSelectionStateType>({
        auditName: "",
        isReadOnly: false,
        selectableBins: [],
        selectedBins: new Set<string>()
    });

    const getAuditDetailsCallback = React.useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            apiService.dsp.getAudit(Number(auditId))
                .then((response: GetDspAuditsResponse) => setAuditSelectionState(prevState => {
                    return {
                        ...prevState,
                        auditName: response.audits[0].auditName
                    }
                }))
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, auditId]);

    const setAuditNameCallback = React.useCallback((newAuditName: string): void => {
        setAuditSelectionState(prevState => {
            return {
                ...prevState,
                auditName: newAuditName
            };
        });
    }, []);

    const updateAuditNameCallback = React.useCallback((): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                await apiService.dsp.updateAuditName(Number(auditId), auditSelectionState.auditName);
                
                await getAuditDetailsCallback();
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService, auditId, auditSelectionState.auditName, getAuditDetailsCallback]);

    const changeIsRowSelectedCallback = React.useCallback((binName: string): void => {
        setAuditSelectionState(prevState => {
            const newSelectedBins = new Set(prevState.selectedBins);

            if (newSelectedBins.has(binName)) {
                newSelectedBins.delete(binName);
            } else {
                newSelectedBins.add(binName);
            }
            return {
                ...prevState,
                selectedBins: newSelectedBins
            };
        });
    }, []);

    const toggleSelectAllRowsCallback = React.useCallback((): void => {
        setAuditSelectionState(prevState => {
            const newSelectedBins = prevState.selectedBins.size < prevState.selectableBins.length
                ? new Set<string>(prevState.selectableBins.map(bin => bin.binName))
                : new Set<string>()
            return {
                ...prevState,
                selectedBins: newSelectedBins
            }
        });
    },[]);

    const setIsReadOnlyCallback = React.useCallback((newValue: boolean): void => {
        setAuditSelectionState(prevState => {
            return {
                ...prevState,
                isReadOnly: newValue
            };
        });
    }, []);

    const deleteAuditCallback = React.useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            apiService.dsp.deleteAudit(Number(auditId))
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, auditId]);

    const setAuditBinsCallback = React.useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            const includedBins = auditSelectionState.selectableBins.filter((value: DspAuditBin) => {
                return auditSelectionState.selectedBins.has(value.binName);
            });

            apiService.dsp.setAuditBins(Number(auditId), includedBins)
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, auditId, auditSelectionState]);
    
    React.useEffect(() => {
        getAuditDetailsCallback();
    }, [getAuditDetailsCallback]);

    React.useEffect(() => {
        apiService.dsp.getAuditTargets()
            .then((response: GetDspAuditTargetsResponse) => setAuditSelectionState(prevState => {
                return {
                    ...prevState,
                    selectableBins: response.bins
                };
            }));
    }, [apiService]);

    return (
        <DspAuditSelectionContext.Provider
            value={{
                auditName: auditSelectionState.auditName,
                isReadOnly: auditSelectionState.isReadOnly,
                selectableBins: auditSelectionState.selectableBins,
                selectedBins: auditSelectionState.selectedBins,
                changeIsRowSelected: changeIsRowSelectedCallback,
                deleteAudit: deleteAuditCallback,
                setAuditBins: setAuditBinsCallback,
                setNewAuditName: setAuditNameCallback,
                setIsReadOnly: setIsReadOnlyCallback,
                toggleSelectAllRows: toggleSelectAllRowsCallback,
                updateAuditName: updateAuditNameCallback
            }}
            >
            {props.children}
        </DspAuditSelectionContext.Provider>
    );
}

function useDspAuditSelectionContext(): DspAuditSelectionContextType {
    const context = React.useContext(DspAuditSelectionContext);

    if (!context) {
        throw new Error("useDspAuditSelectionContext must be used within a DspAuditSelectionProvider");
    }

    return context;
}

export { DspAuditSelectionProvider, useDspAuditSelectionContext }