import React from "react";
import { useApiService } from "../../base/providers";
import { AuditDetailsDataGrid } from "./auditDetailsDataGrid";
import { VehicleRecord } from "../../../types/dsv/audit";
import { useParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { LocationSelectionDropdown } from "./locationSelectionDropdown";
import { PrintVinLabelsButton } from "../print/printVinLabelsButton";
import { BackToAuditHistoryButton } from "./backToAuditHistoryButton";
import { NavButtons } from "../../base/navButtons";

function AuditDetails(): JSX.Element {
    const { auditId } = useParams();
    const { apiService } = useApiService();
    const [locationIds, setLocationIds] = React.useState<string[]>([]);
    const [vehicleRecords, setVehicleRecords] = React.useState<VehicleRecord[]>([]);

    const setSelectedStoreOptionIdsCallback = React.useCallback((storeOptionIds: string[]): void => {
        if (!storeOptionIds || storeOptionIds.length === 0) {
            return;
        }

        setLocationIds(storeOptionIds);
    }, []);

    React.useEffect(() => {
        if (locationIds.length === 0) {
            return;
        }

        const auditIdNum = Number(auditId);
        const locationIdNums = locationIds.map((locationId: string) => Number(locationId));
        apiService.dsv.getVehicles(auditIdNum, locationIdNums)
            .then((vehicleRecordsArr: VehicleRecord[]) => {
                setVehicleRecords(vehicleRecordsArr);
            })
            .catch((err) => console.error(`Unable to retrieve vehicle records for audit '${auditId}`, err));
    }, [apiService, auditId, locationIds]);

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <BackToAuditHistoryButton />
            <Stack direction="row">
                <Typography
                    variant="h5"
                    sx={{
                        flexGrow: 1
                    }}
                    >
                    Audit Details
                </Typography>
                <NavButtons
                    showSetup={true}
                    setupPath="/vehicles/setup"
                    >
                    <PrintVinLabelsButton />
                </NavButtons>
            </Stack>
            <Stack
                direction="row"
                spacing={2}
                >
                <LocationSelectionDropdown
                    selectedStoreOptionIds={locationIds}
                    setSelectedStoreOptionIds={setSelectedStoreOptionIdsCallback}
                    />
            </Stack>
            <AuditDetailsDataGrid
                rows={vehicleRecords}
                vehicleAuditId={Number(auditId)}
                />
        </Stack>
    );
}

export { AuditDetails }