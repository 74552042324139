import React from "react";
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { useDspAuditFlagContext } from "../context";
import { CenteredFormView } from "../../base/format/centeredFormView";
import { SetDspAuditFlagResponse } from "types/admin";

type SnackbarStateType = {
    isOpen: boolean
    message: string
}

function DspAuditFlagView(): JSX.Element {
    const { formState, setDealerId, setLocationId, setIsEnabled, submitForm } = useDspAuditFlagContext();
    const [snackbarState, setSnackbarState] = React.useState<SnackbarStateType>({
        isOpen: false,
        message: ""
    });

    const onChangeDealerId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setDealerId(e.target.value || "");
    }

    const onChangeLocationId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setLocationId(e.target.valueAsNumber);
    }
    
    const onChangeIsEnabled = (e: SelectChangeEvent): void => {
        e.preventDefault();

        const newValue = e.target.value;
        if (newValue !== "0" && newValue !== "1") {
            return;
        }
        setIsEnabled(newValue === "1" ? true : false);
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        submitForm()
            .then((response: SetDspAuditFlagResponse) => setSnackbarState({
                isOpen: true,
                message: `Set ${response.isEnabled ? "enabled" : "disabled"} for dealer ${response.dealerId} location ${response.locationId}`
            }))
            .catch((err) => {
                console.error("Unable to update DSP audit state", err);
                setSnackbarState({
                    isOpen: true,
                    message: `Unable to set permission for auditing.`
                });
            });
    }

    const onCloseSnackbar = (): void => {
        setSnackbarState(prevState => {
            return {
                ...prevState,
                isOpen: false
            };
        });
    }

    return (
        <CenteredFormView>
            <Stack
                autoComplete="off"
                component="form"
                direction="column"
                spacing={2}
                >
                <Typography variant="h5">Toggle DSP Audit Flag</Typography>
                <TextField
                    id="dealerId"
                    label="Dealer ID"
                    onChange={onChangeDealerId}
                    value={formState.dealerId}
                    />
                <TextField
                    id="locationId"
                    label="Location ID"
                    onChange={onChangeLocationId}
                    type="number"
                    value={formState.locationId}
                    />
                <FormControl>
                    <InputLabel id="label-is-enabled">Is Enabled?</InputLabel>
                    <Select
                        id="isEnabled"
                        label="Is Enabled?"
                        labelId="label-is-enabled"
                        onChange={onChangeIsEnabled}
                        value={formState.isEnabled ? "1" : "0"}
                        >
                        <MenuItem value="0">No</MenuItem>
                        <MenuItem value="1">Yes</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    color="primary"
                    onClick={onClickSubmit}
                    type="submit"
                    variant="contained"
                    >
                    Submit
                </Button>
                <Snackbar
                    anchorOrigin={{
                        horizontal: "center",
                        vertical: "top"
                    }}
                    autoHideDuration={5000}
                    message={snackbarState.message}
                    onClose={onCloseSnackbar}
                    open={snackbarState.isOpen}
                    />
            </Stack>
        </CenteredFormView>
    );
}

export { DspAuditFlagView }