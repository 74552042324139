import { AppBar, Box, Button, Stack, Toolbar, Typography } from "@mui/material";
import { AuthButton } from "./authButton";
import { useNavigate } from "react-router-dom";
import { useAdminContext } from "../providers";

function NavBar(): JSX.Element {
    const { isAdmin } = useAdminContext();
    const navigate = useNavigate();

    const onClickAdmin = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate("/admin");
    }

    const onClickVehicles = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate("/vehicles");
    }

    const onClickParts = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        
        navigate("/parts");
    }

    return(
        <Box flexGrow={1}>
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        >
                        DealerBuilt Scan
                    </Typography>
                    <Stack
                        component={Box}
                        direction="row"
                        flexGrow={1}
                        paddingLeft={2}
                        spacing={1}
                        >
                        <Button
                            color="inherit"
                            disabled={!isAdmin}
                            onClick={onClickAdmin}
                            variant="outlined"
                            sx={{
                                display: isAdmin ? "inherit" : "none"
                            }}
                            >
                            Admin
                        </Button>
                        <Button
                            color="inherit"
                            onClick={onClickVehicles}
                            variant="outlined"
                            >
                            Vehicles
                        </Button>
                        <Button
                            color="inherit"
                            onClick={onClickParts}
                            variant="outlined"
                            >
                            Parts
                        </Button>
                    </Stack>
                    <AuthButton
                        color="inherit"
                        variant="outlined"
                        />
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export { NavBar };