import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import React from "react";
import { Utilities } from "../../../../../../../../../utilities";

type BaseCardProps = {
    displayValueAs?: "currency" | "percentage"
    title: string
    useAccountingColors?: boolean
    value: number
}

type BaseCardState = {
    displayColor?: "black" | "green" | "red"
    displayValue: string
}

function BaseCard(props: BaseCardProps): JSX.Element {
    const { displayValueAs, title, useAccountingColors, value } = props;
    const [state, setState] = React.useState<BaseCardState>({
        displayValue: ""
    });

    React.useEffect(() => {
        setState(prevState => {
            const displayColor = value === 0
                ? "black"
                : value > 0
                    ? "green"
                    : "red";

            return {
                ...prevState,
                displayColor: useAccountingColors
                    ? displayColor
                    : undefined
            };
        });
    }, [useAccountingColors, value]);

    React.useEffect(() => {
        setState(prevState => {
            const displayValue = displayValueAs === "currency"
                ? Utilities.displayAsCurrency(value)
                : displayValueAs === "percentage"
                    ? Utilities.displayAsPercentage(value)
                    : `${value}`;
            
            return {
                ...prevState,
                displayValue: displayValue
            }
        });
    }, [displayValueAs, value])

    return (
        <Grid
            item={true}
            xs={2}
            >
            <Card
                sx={{
                    background: "#f8f8f8",
                    border: "solid 1px #d8d6de"
                }}
                >
                <CardHeader title={
                    <Typography
                        color="#746E8B"
                        justifySelf="center"
                        variant="body1"
                        >
                        {title}
                    </Typography>
                    }
                    />
                <CardContent>
                    <Typography
                        color={state.displayColor}
                        justifySelf="center"
                        variant="h6"
                        >
                        {state.displayValue}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
        
    );
}

export { BaseCard }