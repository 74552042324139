import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function PrintVinLabelsButton(): JSX.Element {
    const navigate = useNavigate();

    const onClickPrintLabels = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate("/vehicles/print");
    }

    return (
        <Button
            onClick={onClickPrintLabels}
            variant="outlined"
            >
            Print Labels
        </Button>
    )
}

export { PrintVinLabelsButton }