import React from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useDspAuditSelectionContext } from "../context";
import { BinSelectionDataGrid } from "../dataGrid";
import { SelectionNavigationView } from "../navigation";
import { SelectionActionView } from "../actions";

function SelectionDataView(): JSX.Element {
    const { auditName, setNewAuditName, updateAuditName } = useDspAuditSelectionContext();
    const [updatingAuditName, setUpdatingAuditName] = React.useState(false);

    React.useEffect(() => {
        if (!updatingAuditName) {
            return;
        }

        updateAuditName()
            .then(() => setUpdatingAuditName(false))
            .catch((err) => console.error("Unable to update audit name", err));
    }, [updatingAuditName, updateAuditName]);

    const onChangeAuditName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setNewAuditName(e.target.value || "");
    }

    const onClickChange = (): void => {
        setUpdatingAuditName(true);
    }
    
    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Stack
                direction = "row"
                spacing={2}
                >
                <TextField
                    onChange={onChangeAuditName}
                    value={auditName}
                    />
                    <Button
                        disabled={updatingAuditName}
                        onClick={onClickChange}
                        variant="contained"
                        >
                        Change    
                    </Button>
            </Stack>
            <Typography variant="h6">Choose Bins/Parts</Typography>
            <Stack
                direction="column"
                spacing={2}
                >
                <SelectionNavigationView />
                <BinSelectionDataGrid />
                <SelectionActionView />
            </Stack>
        </Stack>
    );
}

export { SelectionDataView }