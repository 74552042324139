import { differenceInMinutes } from "date-fns";

class Utilities {
    static getQueryParamsMap(input: string): Map<string, string> {
        const queryParams = new URLSearchParams(window.location.search);

        const map = new Map<string, string>();

        if (!queryParams) {
            return map;
        }
        
        for (const [key, value] of queryParams.entries()) {
            map.set(key, value);
        }

        return map;
    }

    static openLoginPopup(url: string, screen: Screen): Promise<Window> {
        return new Promise((resolve, reject): void => {
            try {
                const width = 400;
                const height = 600;
                const left = (screen.width / 2) - (width / 2);
                const top = (screen.height / 2) - (height / 2);

                const popup = window.open(url, "_blank", `width=${width},height=${height},top=${top},left=${left}`);

                if (!popup) {
                    throw Error("Please allow popups for this website.");
                }

                resolve(popup);
            } catch (err: any) {
                reject(err);
            }
        });
    }

    static hookLoginPopup(popup: Window, expectedOrigin: string, handleMessage: (data: any, popup: Window) => void): Promise<void> {
        return new Promise((resolve, reject): void => {
            try {
                window.addEventListener("message", (event: MessageEvent<any>) => {
                    if(event.origin !== expectedOrigin) {
                        return;
                    }

                    handleMessage(event.data, popup);
                });

                popup.focus();
                resolve();
            } catch (err: any) {
                reject(err);
            }
        })
    }

    static getLotStringFromLastScannedLocation(lastScannedLocation: string): string {
        const match = this.getPartsFromLastScannedLocation(lastScannedLocation);

        if (!match || match.length < 2) {
            return "";
        }

        return match[1];
    }

    static getIsoDateStringFromLastScannedLocation(lastScannedLocation: string): string {
        const match = this.getPartsFromLastScannedLocation(lastScannedLocation);
        
        if (!match || match.length < 3) {
            return "";
        }
        
        const dateParts = match[2].split("/");

        const year = this.convertYearToFourDigit(dateParts[2]);

        return new Date(year, Number(dateParts[0]) - 1, Number(dateParts[1])).toISOString();
    }

    static displayAsCurrency(value: number): string {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    }

    static displayAsPercentage(value: number): string {
        return value.toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }

    static displayDistanceBetweenDates(date1: Date, date2: Date): string {
        const minuteDifference = differenceInMinutes(date1, date2);

        if (minuteDifference === 0) {
            return "< 1 minute";
        }

        return `${minuteDifference} minutes`;
    }

    private static getPartsFromLastScannedLocation(lastScannedLocation: string): RegExpMatchArray | undefined {
        const pattern = /^(.*)\b(\d{1,2}\/\d{1,2}\/\d{2,4})\b$/;
        const match = lastScannedLocation.match(pattern);

        if (!match) {
            return undefined;
        }

        return match;
    }

    private static convertYearToFourDigit(yearStr: string): number {
        let year = Number(yearStr);

        // DM 09/04/2024 Refer to ASG-627 regarding why we do this. Javascript was parsing years that are two-digit as 1900s.
        // Ex: 24 -> 1924 -- Therefore we are fixing this by creating a 4-digit year.
        if (year < 100) {
            year += 2000;
        }

        return year;
    }
}

export { Utilities };