type DsvPrinterTypeDef = {
    id: number
    value: string
}

class DsvPrinterType {
    private static printerTypes: DsvPrinterTypeDef[] = [
        { id: 1, value: "AMT/Fastmark 4602" },
        { id: 2, value: "Monarch 9825" },
        { id: 3, value: "AMT/Fastmark M7+" },
        { id: 4, value: "ZPL II" }
    ];

    static getAllTypes(): DsvPrinterTypeDef[] {
        return this.printerTypes;
    }

    static map(key: number): DsvPrinterTypeDef {
        const foundVal = this.printerTypes.find(def => def.id === key);

        if (!foundVal) {
            throw new Error("Unable to map printer type");
        }

        return foundVal;
    }

    static mapValue(key: number): string {
        try {
            var foundVal = this.map(key);
            return foundVal.value;
        } catch {
            return "Unknown";
        }
    }

    static isValidMapping(key: number): boolean {
        try {
            this.map(key);
            return true;
        } catch {
            return false;
        }
    }
}

export { DsvPrinterType }
export type { DsvPrinterTypeDef }