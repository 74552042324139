import { AuthProvider } from './components/base';
import { AdminProvider, ApiServiceProvider } from './components/base/providers';
import { RouterProvider } from 'react-router-dom';
import { appRouter } from './appRouter';

function App(): JSX.Element {

    return (
        <AuthProvider>
            <ApiServiceProvider>
                    <AdminProvider>
                        <RouterProvider router={appRouter} />
                    </AdminProvider>
            </ApiServiceProvider>
        </AuthProvider>
    );
}

export { App };
