
import React from "react";
import { useApiService } from "../../../base/providers";
import { Box, Stack, Typography } from "@mui/material";
import { CreateLotForm, CreateLotFormValues } from "./createLotForm";
import { CreateLotButton } from "./createLotButton";
import { LotSetupDataGrid } from "./lotSetupDataGrid";
import { AuditLocation } from "../../../../types/dsv/setup";
import { GenericModal } from "../../../base/modal";

function LotSetup(): JSX.Element {
    const { apiService } = useApiService();
    const [lotLocations, setLotLocations] = React.useState<AuditLocation[]>([]);
    const [showCreateLotModal, setShowCreateLotModal] = React.useState(false);
    
    const getAllAuditLocationsCallback = React.useCallback(() => {
        apiService.dsv.getAllAuditLocations()
            .then((auditLocationArr) => setLotLocations(auditLocationArr))
            .catch((err) => console.error("Unable to retrieve lot locations", err));
    }, [apiService, setLotLocations]);

    const showCreateLotModalCallback = React.useCallback(() => {
        setShowCreateLotModal(true);
    }, [setShowCreateLotModal]);
    
    const hideCreateLotModalCallback = React.useCallback(() => {
        setShowCreateLotModal(false);
    }, [setShowCreateLotModal]);

    const submitCreateLotModalCallback = React.useCallback((formValues: CreateLotFormValues): void => {
        const locationId = Number(formValues.locationId);

        if (isNaN(locationId)) {
            console.error("Location id is not valid", formValues.locationId);
            return;
        }

        apiService.dsv.addAuditLocation(formValues.lotName, locationId)
            .then(() => getAllAuditLocationsCallback())
            .then(() => setShowCreateLotModal(false))
            .catch((err) => console.error("Unable to add audit location", err));
    }, [apiService, getAllAuditLocationsCallback]);

    React.useEffect(() => {
        getAllAuditLocationsCallback();
    }, [getAllAuditLocationsCallback]);

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Stack direction="row">
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h5">Lot Setup</Typography>
                </Box>
                <GenericModal
                    showModal={showCreateLotModal}
                    hideModalCallback={hideCreateLotModalCallback}
                    dialogTitle="Create Lot"
                    >
                    <CreateLotForm
                        clickSubmitCallback={submitCreateLotModalCallback}
                        />
                </GenericModal>
                <CreateLotButton
                    onClickAddLotCallback={showCreateLotModalCallback}
                    />
            </Stack>
            <LotSetupDataGrid
                rows={lotLocations}
                reloadDataCallback={getAllAuditLocationsCallback}
                />
        </Stack>
    );
}

export { LotSetup }