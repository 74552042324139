import { Button } from "@mui/material";
import { FilterOptionChip } from "./chip";

type FilterOptionButtonProps = {
    count?: number
    label: string
    variant: "outlined" | "text"
    onClickFilterOptionCallback: () => void
}

function FilterOptionButton(props: FilterOptionButtonProps): JSX.Element {
    const { count, label, variant, onClickFilterOptionCallback } = props;

    const onClickFilterOption = (): void => {
        onClickFilterOptionCallback();
    }

    return (
        <Button
            onClick={onClickFilterOption}
            variant={variant}
            >
            {label}&nbsp;<FilterOptionChip count={count} />
        </Button>
    );
}

export { FilterOptionButton }