import { PreDefinedFilterModel } from "../.."

type FilterState = {
    filterModels?: PreDefinedFilterModel[]
    filter?: PreDefinedFilterModel
    filterButtons: JSX.Element[]
}

type FilterAction =
    | { type: "UPDATE_FILTER_MODEL_ARR", payload: PreDefinedFilterModel[] | undefined }
    | { type: "SET_FILTER_MODEL", payload: PreDefinedFilterModel }
    | { type: "SET_FILTER_BUTTONS", payload: JSX.Element[] }

const filterInitialState: FilterState = {
    filter: undefined,
    filterButtons: []
}

function FilterReducer(state: FilterState, action: FilterAction): FilterState {
    switch (action.type) {
        case "UPDATE_FILTER_MODEL_ARR":
            return {
                ...state,
                filterModels: action.payload,
                filter: action.payload && action.payload.length > 0
                    ? action.payload[0]
                    : undefined
            }
        case "SET_FILTER_MODEL":
            return {
                ...state,
                filter: action.payload
            };
        case "SET_FILTER_BUTTONS":
            return {
                ...state,
                filterButtons: action.payload
            };
    }
}

export {
    FilterReducer,
    filterInitialState
}