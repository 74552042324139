import { Button } from "@mui/material";

type CreateLotButtonProps = {
    onClickAddLotCallback: () => void
}

function CreateLotButton(props: CreateLotButtonProps): JSX.Element {
    const { onClickAddLotCallback } = props;

    const onClickAddLot = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        onClickAddLotCallback();
    }

    return (
        <>
            <Button
                color="primary"
                onClick={onClickAddLot}
                variant="contained"
                >
                Add Lot
                </Button>
        </>
        
    );
}

export { CreateLotButton }