import React from "react";
import { BaseCard } from "../base";
import { useDspAuditDetailsContext } from "../../../../../context";
import { DspAuditVarianceReport } from "types/dsp/audits";

function OverallVarianceCard(): JSX.Element {
    const { auditVariances } = useDspAuditDetailsContext();
    const [displayValue, setDisplayValue] = React.useState(0);

    React.useEffect(() => {
        const newQuantityTotal = auditVariances.map((value: DspAuditVarianceReport) => value.newQuantity || value.scanned)
            .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);

        const expectedQuantityTotal = auditVariances.map((value: DspAuditVarianceReport) => value.expected)
            .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);

        const overallVariance = (newQuantityTotal - expectedQuantityTotal) / expectedQuantityTotal;
            
        setDisplayValue(overallVariance);
    }, [auditVariances]);
    
    return (
        <BaseCard
            displayValueAs="percentage"
            title="Overall Variance"
            value={displayValue}
            useAccountingColors={true}
            />
    );
}

export { OverallVarianceCard }