import { IconButton } from "@mui/material";
import RestoreIcon from '@mui/icons-material/Restore';
import { useDspAuditVarianceContext } from "../../../context";

type ResetButtonProps = {
    lightyearId: number
}

function ResetButton({ lightyearId }: ResetButtonProps): JSX.Element {
    const { resetNewQuantity } = useDspAuditVarianceContext();

    const onClickResetButton = (): void => {
        resetNewQuantity(lightyearId);
    }

    return (
        <IconButton onClick={onClickResetButton}>
            <RestoreIcon fontSize="small"/>
        </IconButton>
    );
}

export { ResetButton }