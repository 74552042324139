import React from 'react';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Tooltip } from '@mui/material';

type OpenOrderFlagProps = {
    openOrderCount: number
}

function OpenOrderFlag({ openOrderCount }: OpenOrderFlagProps): JSX.Element {
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            if (openOrderCount === 0) {
                return (<></>);
            }

            return (
                <Tooltip title={`${openOrderCount} pending open order(s)`}>
                    <LocalShippingIcon color="error" />
                </Tooltip>
            );
        });
    }, [openOrderCount]);

    return (component);
}

export { OpenOrderFlag }