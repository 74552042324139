import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function BackToAuditsButton(): JSX.Element {
    const navigate = useNavigate();

    const onClickBackToAuditDetails = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate("/parts/audit");
    }
    
    return (
        <Button
            onClick={onClickBackToAuditDetails}
            variant="text"
            sx={{
                alignSelf: "start",
                minWidth: "auto",
                width: "fit-content"
            }}
            >
            &lt;&nbsp;Back to Audits
        </Button>
    );
}

export { BackToAuditsButton }