import { GridColDef } from "@mui/x-data-grid"
import { Checkbox, MenuItem, Select } from "@mui/material";
import { Utilities } from "../../../../../../utilities";

type ColumnProps = {
    isReadOnly: boolean
    selectedRows: Set<string>
    onChangeIsRowSelected: (binName: string) => void
}

function columns(props: ColumnProps): GridColDef[] {
    return [
        {
            field: "id",
            flex: 1,
            headerName: "ID",
            hideable: true
        },
        {
            field: "isSelected",
            flex: 0.5,
            headerName: "",
            renderCell: (params) => (
                <Checkbox
                    checked={props.selectedRows.has(params.row.binName)}
                    disabled={props.isReadOnly}
                    onChange={() => props.onChangeIsRowSelected(params.row.binName)}
                    />
            )
        },
        {
            field: "binName",
            flex: 1,
            headerName: "Bin"
        },
        {
            field: "uniqueParts",
            flex: 1,
            headerName: "Part Numbers"
        },
        {
            field: "totalParts",
            flex: 1,
            headerName: "Total Parts"
        },
        {
            field: "binDollarValue",
            flex: 1,
            headerName: "Dollar Value of Bin",
            valueFormatter: (value: number) => Utilities.displayAsCurrency(value)
        },
        {
            field: "lastAuditDate",
            flex: 1,
            headerName: "Last Audit Date",
            valueFormatter: (value: Date | null) => {
                if (!value) {
                    return "-";
                }

                return new Date(value).toLocaleString("en-US");
            }
        },
        {
            field: "auditor",
            flex: 1.25,
            headerName: "Auditor",
            hideable: true,
            renderCell: () => (
                <Select value={1}>
                    <MenuItem value={1}>Not Yet Implemented</MenuItem>
                </Select>
            )
        }
    ];
}

export { columns }