import { Button, Stack } from "@mui/material";
import { useDspAuditSelectionContext } from "../context";
import { useNavigate } from "react-router-dom";

function SelectionActionView(): JSX.Element {
    const { isReadOnly, deleteAudit, setAuditBins, setIsReadOnly } = useDspAuditSelectionContext();
    const navigate = useNavigate();

    const onClickPrimaryAction = (): void => {
        if (!isReadOnly) {
            setIsReadOnly(true);
        } else {
            setAuditBins()
                .then(() => navigate("/parts"))
                .catch((err) => {
                    console.error("Unable to add bins to audit", err);
                });
        }
    }

    const onClickSecondaryAction = (): void => {
        if (isReadOnly) {
            setIsReadOnly(false);
        } else {
            deleteAudit()
                .then(() => navigate("/parts"))
                .catch((err) => {
                    console.error("Unable to delete audit", err);
                });
        }
    }

    return (
        <Stack
            direction="row-reverse"
            spacing={2}
            >
            <Button
                onClick={onClickPrimaryAction}
                variant="contained"
                >
                { isReadOnly ? "Create Audit" : "Review Audit"}
            </Button>
            <Button
                onClick={onClickSecondaryAction}
                variant="outlined"
                >
                { isReadOnly ? "Go Back" : "Cancel"}
            </Button>
        </Stack>
    );
}

export { SelectionActionView }