import { Stack } from "@mui/material";
import { BackToAuditsButton } from "../../buttons";
import { CompleteDetailsDataGrid } from "../../dataGrid/complete";
import { CardRow } from "./cardRow";
import { TitleRow } from "./titleRow";

function CompleteDetailsView(): JSX.Element {
    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <BackToAuditsButton />
            <TitleRow />
            <CardRow />
            <CompleteDetailsDataGrid />
        </Stack>
    );
}

export { CompleteDetailsView }