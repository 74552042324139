import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import { ContentContainer } from "./body";
import { NavBar } from "./navBar";

type TitleMap = {
    [key: string]: string
}

const titleMap: TitleMap = {
    "/vehicles": "Vehicles",
    "/parts": "Parts"
};

function Layout(): JSX.Element {
    const location = useLocation();

    React.useEffect(() => {
        const pathName = location.pathname;

        const mappedTitle = Object.keys(titleMap).find((key: string) => pathName.toUpperCase().startsWith(key.toUpperCase()));

        if (!mappedTitle) {
            document.title = "DealerBuilt Scan Web";
            return;
        }

        document.title = `DealerBuilt Scan Web - ${titleMap[mappedTitle]}`;
    }, [location]);

    return (
        <>
            <NavBar />
            <Container
                maxWidth="xl"
                sx={{
                    paddingTop: 5,
                    paddingBottom: 5
                }}
                >
                <ContentContainer />
            </Container>
        </>
    )
}

export { Layout }