import React from "react";
import { AuditLocation } from "../../../../types/dsv/setup";
import { GridColDef } from "@mui/x-data-grid";
import { useApiService } from "../../../base/providers";
import { DeleteButton, EditButton } from "../../../base/crud";
import { EditLotForm, EditLotFormValues } from "./editLotForm";
import { GenericModal } from "../../../base/modal";
import { GenericDataGrid } from "../../../base/dataGrid";

type LotSetupDataGridProps = {
    rows: AuditLocation[]
    reloadDataCallback: () => void
}

type MappedRow = {
    id: number
    lotName: string
    locationName: string
    locationNumber: number
    editId: number
    deleteId: number
}

function LotSetupDataGrid(props: LotSetupDataGridProps): JSX.Element {
    const { rows, reloadDataCallback } = props;
    const { apiService } = useApiService();
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [editLotId, setEditLotId] = React.useState(-1);
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    const hideEditModalCallback = React.useCallback(() => {
        setShowEditModal(false);
    }, [setShowEditModal]);

    const editActionCallback = React.useCallback((editId: number): void => {
        setEditLotId(editId);
        setShowEditModal(true);
    }, []);

    const editClickSubmitCallback = React.useCallback((formValues: EditLotFormValues): void => {
        const locationId = Number(formValues.locationId);

        if (isNaN(locationId)) {
            console.error("Location id is not valid", formValues.locationId);
            return;
        }

        apiService.dsv.updateAuditLocation(formValues.id, formValues.lotName, locationId)
            .then(() => reloadDataCallback())
            .then(() => setShowEditModal(false))
            .catch((err: any) => console.error("An error occurred while submitting edit to audit location", err));
    }, [apiService, reloadDataCallback]);

    const deleteActionCallback = React.useCallback((deleteId: number): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                await apiService.dsv.deleteAuditLocation(deleteId);
                reloadDataCallback();
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }, [apiService, reloadDataCallback]);

    React.useEffect(() => {
        if (!rows) {
            return;
        }

        setMappedRows(rows.map((value: AuditLocation): MappedRow => {
            return {
                id: value.auditLocationId,
                lotName: value.locationName,
                locationName: value.cocoName,
                locationNumber: value.lySetupLocationId,
                editId: value.auditLocationId,
                deleteId: value.auditLocationId
            }
        }));
    }, [rows])

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            hideable: false,
            width: 90
        },
        {
            field: "lotName",
            flex: 1,
            headerName: "Lot Name",
            hideable: false
        },
        {
            field: "locationName",
            flex: 1,
            headerName: "Location Name",
            hideable: false
        },
        {
            field: "locationNumber",
            flex: 1,
            headerName: "Location Number",
            hideable: false
        },
        {
            field: "editId",
            filterable: false,
            headerName: "",
            hideable: false,
            renderCell: (params) => (
                <EditButton
                    editId={params.value}
                    editAction={editActionCallback}
                    />
            ),
            sortable: false,
            width: 100
        },
        {
            field: "deleteId",
            filterable: false,
            headerName: "",
            hideable: false,
            renderCell: (params) => (
                <DeleteButton
                    deleteId={params.value}
                    deleteAction={deleteActionCallback}
                    />
            ),
            sortable: false,
            width: 100
        }
    ];

    return (
        <>
            <GenericModal
                showModal={showEditModal}
                hideModalCallback={hideEditModalCallback}
                dialogTitle="Edit Lot"
                >
                <EditLotForm
                    id={editLotId}
                    clickSubmitCallback={editClickSubmitCallback}
                    />
            </GenericModal>
            <GenericDataGrid
                columns={columns}
                initialStatePagination={{
                    page: 0,
                    pageSize: 25
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                rows={mappedRows}
                />
        </>
        
    );
}

export { LotSetupDataGrid }