import { Box } from "@mui/material";
import { GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

function AuditDetailsDataGridToolbar(): JSX.Element {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <Box sx={{ flexGrow: 1 }} />
            <GridToolbarExport
                csvOptions={{
                    allColumns: true
                }}
                printOptions={{
                    disableToolbarButton: true
                }}
                />
        </GridToolbarContainer>
    )
}

export {
    AuditDetailsDataGridToolbar
}