import { ApiService } from "./apiService";
import { AxiosInstance } from "axios";
import { GetAllVehicleAuditsResponse, GetVehicleReportResponse, VehicleAudit, VehicleRecord } from "../types/dsv/audit";
import { AuditLocation, DsvPrinter, GetAuditLocationResponse, GetDsvPrintersResponse } from "../types/dsv/setup";
import { StoreOption, StoreOptionResponse } from "../types/auth/locations";
import { GetPrintInstructionsRespose } from "../types/dsv/print";

class DsvApiService extends ApiService {
    super(api: AxiosInstance) { }

    getAllVehicleAudits(): Promise<VehicleAudit[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/dsv/audit/getallvehicleaudits");
                const getAllVehicleAuditsResponse = response.data as GetAllVehicleAuditsResponse;
                resolve(getAllVehicleAuditsResponse.vehicleAudits);
            } catch (err) {
                reject(err);
            }
        });
    }

    deleteVehicleAudit(auditId: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.delete(`/dsv/audit/deletevehicleaudit?auditId=${auditId}`);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                } 
                
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    getAllPrinters(): Promise<DsvPrinter[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/dsv/print/getallprinters");
                const getDsvPrintersResponse = response.data as GetDsvPrintersResponse;
                resolve(getDsvPrintersResponse.printers);
            } catch (err) {
                reject(err);
            }
        });
    }

    getPrinterById(printerId: number): Promise<DsvPrinter> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get(`dsv/print/getprinterbyid?printerid=${printerId}`);
                const getDsvPrintersResponse = response.data as GetDsvPrintersResponse;
                resolve(getDsvPrintersResponse.printers[0]);
            } catch (err) {
                reject(err);
            }
        });
    }

    addPrinter(printerName: string, printerType: number, host: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("/dsv/print/addprinter", {
                    name: printerName,
                    printerType: printerType,
                    host: host
                });

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    updatePrinter(printerId: number, name: string, printerType: number, host: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.patch("/dsv/print/updateprinter", {
                    printerId: printerId,
                    name: name,
                    printerType: printerType,
                    host: host
                });

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    deletePrinter(printerId: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.delete(`/dsv/print/deleteprinter?printerId=${printerId}`);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    getAllAuditLocations(): Promise<AuditLocation[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/dsv/auditlocation/getallauditlocations");
                const getAuditLocationResponse = response.data as GetAuditLocationResponse;
                resolve(getAuditLocationResponse.auditLocations);
            } catch (err) {
                reject(err);
            }
        });
    }

    getAuditLocationById(auditLocationId: number): Promise<AuditLocation> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get(`/dsv/auditlocation/getauditlocationbyid?auditlocationid=${auditLocationId}`);
                const getAuditLocationResponse = response.data as GetAuditLocationResponse;
                resolve(getAuditLocationResponse.auditLocations[0]);
            } catch (err) {
                reject(err);
            }
        });
    }

    addAuditLocation(lotName: string, locationId: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("/dsv/auditlocation/addauditlocation", {
                    lotName: lotName,
                    locationId: locationId
                });
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    updateAuditLocation(auditLocationId: number, lotName: string, locationId: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.patch("/dsv/auditlocation/updateauditlocation", {
                    auditLocationId: auditLocationId,
                    lotName: lotName,
                    locationId: locationId
                });
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    deleteAuditLocation(auditLocationId: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.delete(`/dsv/auditlocation/deleteauditlocation?auditLocationId=${auditLocationId}`);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    getVehicles(auditId: number, locationIds: number[]): Promise<VehicleRecord[]> {
        return new Promise(async (resolve, reject) => {
            try {
                if (locationIds.length === 0) {
                    resolve([]);
                }

                const locationIdQueryStrParam = locationIds.map((locationId: number) => `locationIds=${locationId}`).join("&");
                const response = await this.api.get(`/dsv/audit/getvehicles?auditId=${auditId}&${locationIdQueryStrParam}`);
                const getVehicleReportResponse = response.data as GetVehicleReportResponse;
                resolve(getVehicleReportResponse.vehicles);
            } catch (err) {
                reject(err);
            }
        });
    }

    getStoreOptions(): Promise<StoreOption[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/dsv/audit/getstoreoptions");
                const storeOptionsResponse = response.data as StoreOptionResponse;
                resolve(storeOptionsResponse.storeOptions);
            } catch (err) {
                reject(err);
            }
        });
    }

    generatePrintInstructions(stockNumbers: string[], printerId: number): Promise<GetPrintInstructionsRespose> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("/dsv/label/generateprintinstructions", {
                    stockNumbers: stockNumbers,
                    printerId: printerId
                });

                const getPrintInstructionsResponse = response.data as GetPrintInstructionsRespose;

                resolve(getPrintInstructionsResponse)
            } catch (err) {
                reject(err);
            }
        });
    }
}

export { DsvApiService }