import { Stack, Typography } from "@mui/material";
import { useDspAuditDetailsContext } from "../../../context";
import { AuditStatusResolver } from "../../../../base/dataGrid/statusResolver";

function TitleRow(): JSX.Element {
    const { auditName, auditStatus } = useDspAuditDetailsContext();
    return (
        <Stack
            direction="row"
            spacing={2}
            >
            <Typography variant="h6">{auditName}</Typography>
            <AuditStatusResolver status={auditStatus} />
        </Stack>
    );
}

export { TitleRow }