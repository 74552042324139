import { Chip } from "@mui/material";

type BinStatusResolverProps = {
    status: number
}

function BinStatusResolver(props: BinStatusResolverProps): JSX.Element {
    switch (props.status) {
        case 0: // Not Started
            return (
                <Chip
                    color="primary"
                    label="Not Started"
                    />
            );
        case 1: // Pending Variance
            return (
                <Chip
                    color="warning"
                    label="Pending Variance"
                    />
            );
        case 2: // Finished
            return (
                <Chip
                    color="success"
                    label="Finished"
                    />
            );
        case 4:
            return (
                <Chip
                    color="warning"
                    label="Pending Rescan"
                    />
            );
        case 3: // Error
        default:
            return (
                <Chip
                    color="error"
                    label="Error"
                    />
            );
    }
}

export { BinStatusResolver }