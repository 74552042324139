import { useAuth } from "../providers";
import { Outlet } from "react-router-dom";
import { UnauthorizedAccessView } from "./unauthorizedAccessView";

function ContentContainer(): JSX.Element {
    const { isLoggedIn } = useAuth();

    if (!isLoggedIn) {
        return (<UnauthorizedAccessView />);
    }

    return (<Outlet />);
}

export { ContentContainer };