import React from "react";
import { BaseCard } from "../base";
import { useDspAuditDetailsContext } from "../../../../../context";
import { DspAuditVarianceReport } from "types/dsp/audits";

function NetQuantityVarianceCard(): JSX.Element {
    const { auditVariances } = useDspAuditDetailsContext();
    const [displayValue, setDisplayValue] = React.useState(0);

    React.useEffect(() => {
        const netQuantityVariance = auditVariances.map((value: DspAuditVarianceReport) => {
                return value.newQuantity
                    ? value.newQuantity - value.expected 
                    : value.scanned - value.expected
            })
            .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
            
        setDisplayValue(netQuantityVariance);
    }, [auditVariances]);
    
    return (
        <BaseCard
            title="Net Qty Variance"
            value={displayValue}
            useAccountingColors={true}
            />
    );
}

export { NetQuantityVarianceCard }