import { Stack } from "@mui/material";
import { BackToAuditsButton } from "../../buttons";
import { AuditDetailsTitleRowView } from "./titleRow";
import { PendingDetailsDataGrid } from "../../dataGrid";

function PendingDetailsView(): JSX.Element {
    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <BackToAuditsButton />
            <AuditDetailsTitleRowView />
            <PendingDetailsDataGrid />
        </Stack>
    );
}

export { PendingDetailsView }