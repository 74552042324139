import React from "react";
import { useApiService } from "../../../base/providers";
import { DspPrinter } from "../../../../types/dsp/setup";
import { Box, Stack, Typography } from "@mui/material";
import { GenericModal } from "../../../base/modal";
import { CreatePrinterButton } from "./createPrinterButton";
import { CreatePrinterForm, CreatePrinterFormValues } from "./createPrinterForm";
import { PrinterSetupDataGrid } from "./printersSetupDataGrid";
import { DspPrinterType } from "../../../../utilities/printer";

function PrinterSetup(): JSX.Element {
    const { apiService } = useApiService();
    const [printers, setPrinters] = React.useState<DspPrinter[]>([]);
    const [showCreatePrinterModal, setShowCreatePrinterModal] = React.useState(false);

    const getAllPrintersCallback = React.useCallback(() => {
        apiService.dsp.getAllPrinters()
            .then((dspPrinterArr) => setPrinters(dspPrinterArr))
            .catch((err) => console.error("Unable to retrieve printers", err));
    }, [apiService, setPrinters]);

    const showCreatePrinterModalCallback = React.useCallback(() => {
        setShowCreatePrinterModal(true);
    }, [setShowCreatePrinterModal])

    const hideCreatePrinterModalCallback = React.useCallback(() => {
        setShowCreatePrinterModal(false);
    }, [setShowCreatePrinterModal]);

    const submitCreatePrinterModalCallback = React.useCallback((formValues: CreatePrinterFormValues): void => {
        const printerTypeNum = Number(formValues.printerType);

        if (isNaN(printerTypeNum) || !DspPrinterType.isValidMapping(printerTypeNum)) {
            console.error("Printer type is not valid", formValues.printerType);
            return;
        }

        const portNum = Number(formValues.port);

        if (isNaN(portNum)) {
            console.error("Port is not valid", formValues.port);
            return;
        }

        apiService.dsp.addPrinter(formValues.printerName, formValues.printerShortName, printerTypeNum, formValues.host, portNum)
            .then(() => getAllPrintersCallback())
            .then(() => hideCreatePrinterModalCallback())
            .catch((err) => console.error("Unable to add printer", err));
    }, [apiService, getAllPrintersCallback, hideCreatePrinterModalCallback]);

    React.useEffect(() => {
        getAllPrintersCallback();
    }, [getAllPrintersCallback]);
    
    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Stack direction="row">
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h5">Printer Setup</Typography>
                </Box>
                <CreatePrinterButton
                    onClickAddPrinterCallback={showCreatePrinterModalCallback}
                    />
                <GenericModal
                    showModal={showCreatePrinterModal}
                    hideModalCallback={hideCreatePrinterModalCallback}
                    dialogTitle="Create Printer"
                    >
                    <CreatePrinterForm
                        clickSubmitCallback={submitCreatePrinterModalCallback}
                        />
                </GenericModal>
            </Stack>
            <PrinterSetupDataGrid
                rows={printers}
                reloadDataCallback={getAllPrintersCallback}
                />
        </Stack>
    );
}

export { PrinterSetup }