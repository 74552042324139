import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

function ViewVarianceButton(): JSX.Element {
    const { auditId } = useParams();
    const navigate = useNavigate();

    const onClickViewVariance = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        navigate(`/parts/audit/${auditId}/variance`);
    }
    return (
        <Button
            color="primary"
            onClick={onClickViewVariance}
            variant="contained"
            >
            View Variance
        </Button>
    );
}

export { ViewVarianceButton }