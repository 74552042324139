import { Stack, Typography } from "@mui/material";
import { HandwritesWarning } from "./handwritesWarning";

function ModalCopy(): JSX.Element {
    return (
        <Stack 
            direction="column"
            spacing={2}
            >
            <Typography
                sx={{ fontWeight: "bold" }}
                variant="h6"
                >
                Are you sure you want to accept these new quantities?
            </Typography>
            <HandwritesWarning />
            <Typography variant="body1">The on-hand count for the part(s) will be updated in LightYear with the new quantity from this audit. This will also add an entry in the changelog for the part in LightYear</Typography>
        </Stack>
    );
}

export { ModalCopy }