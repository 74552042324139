import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { useDspAuditContext } from "../../../context";
import { useNavigate } from "react-router-dom";

type CreateAuditFormValues = {
    auditName: string
}

function CreateAuditForm(): JSX.Element {
    const navigate = useNavigate();
    const { submitCreateAudit } = useDspAuditContext();
    const [formValues, setFormValues] = React.useState<CreateAuditFormValues>({
        auditName: `Audit ${new Date().toLocaleDateString()}`
    });

    const onChangeAuditName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFormValues(prevState => ({
            ...prevState,
            auditName: e.target.value || ""
        }));
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        submitCreateAudit(formValues.auditName)
            .then((auditId) => navigate(`/parts/audit/selection/${auditId}`))
            .catch((err) => {
                console.error("Unable to create audit", err);
            });
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            sx={{
                paddingTop: 2
            }}
            >
            <TextField
                id="auditName"
                label="Audit Name"
                onChange={onChangeAuditName}
                value={formValues.auditName}
                />
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="contained"
                >
                Create
            </Button>
        </Stack>
    );
}

export { CreateAuditForm }
export type { CreateAuditFormValues }