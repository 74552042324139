import { useDspAuditDetailsContext } from "../../../../../context";
import { BaseCard } from "../base";

function BinsCard(): JSX.Element {
    const { auditBins } = useDspAuditDetailsContext();
    return (
        <BaseCard
            title="Bins"
            value={auditBins.length}
            />
    );
}

export { BinsCard }