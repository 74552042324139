import React from "react";
import moment from "moment";
import { GridColDef } from "@mui/x-data-grid";
import { VehicleAudit } from "../../../types/dsv/audit";
import { useApiService } from "../../base/providers";
import { DeleteButton } from "../../base/crud";
import { Link } from "react-router-dom";
import { GenericDataGrid } from "../../base/dataGrid";

type VehicleAuditsDataGridProps = {
    rows: VehicleAudit[]
    getAllVehicleAuditsCallback: () => void
}

type MappedRow = {
    id: number
    auditor: string
    scanCount: number
    completedDate: Date
    deleteId: number
}

function VehicleAuditsDataGrid(props: VehicleAuditsDataGridProps): JSX.Element {
    const { apiService } = useApiService();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    const deleteCallback = React.useCallback((deleteId: number): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                await apiService.dsv.deleteVehicleAudit(deleteId);
                props.getAllVehicleAuditsCallback();
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }, [apiService, props]);

    React.useEffect(() => {
        if (!props.rows) {
            return;
        }

        setMappedRows(props.rows.map((value: VehicleAudit): MappedRow => {
            return {
                id: value.vehicleAuditId,
                auditor: value.auditorUserName,
                scanCount: value.scanCount,
                completedDate: value.completedStamp,
                deleteId: value.vehicleAuditId
            }
        }));
    }, [props]);

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            hideable: false,
            renderCell: (params) => (
                <Link to={`/vehicles/audits/${params.value}`}>{params.value}</Link>
            ),
            width: 90
        },
        {
            field: "auditor",
            flex: 1,
            headerName: "Auditor",
            hideable: false
        },
        {
            field: "scanCount",
            flex: 1,
            headerName: "Scan Count",
            hideable: false
        },
        {
            field: "completedDate",
            flex: 1,
            headerName: "Completed Date",
            hideable: false,
            valueFormatter: (value: Date) => {
                return `${moment(value).format("MM/DD/YYYY h:mm:ss a")}`
            }
        },
        {
            field: "deleteId",
            filterable: false,
            headerName: "",
            hideable: false,
            renderCell: (params) => (
                <DeleteButton
                    deleteId={params.value}
                    deleteAction={deleteCallback}
                    />
            ),
            sortable: false,
            width: 100,
        }
    ];

    return (
        <GenericDataGrid
            columns={columns}
            initialStatePagination={{
                page: 0,
                pageSize: 25
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            rows={mappedRows}
            />
    );
}

export { VehicleAuditsDataGrid }