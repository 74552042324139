import { SelectionDataView } from "./data";
import { DspAuditSelectionProvider } from "./context";

function BinSelectionView(): JSX.Element {
    return (
        <DspAuditSelectionProvider>
           <SelectionDataView />
        </DspAuditSelectionProvider>
    );
}

export { BinSelectionView }