import React from "react";
import { Stack } from "@mui/material";
import { AcceptPendingVarianceButton } from "../acceptPendingVarianceButton";
import { RequestRescanButton } from "../requestRescanButton";
import { useDspAuditVarianceContext } from "../../context";

function OptionsResolver(): JSX.Element {
    const { auditVarianceType } = useDspAuditVarianceContext();
    const [component, setComponent] = React.useState<React.ReactNode>();

    React.useEffect(() => {
        setComponent(() => {
            switch (auditVarianceType) {
                case "pending":
                    return (
                        <AcceptPendingVarianceButton key="accept-pending-variance" />
                    );
                case "rescans":
                    return (
                        <RequestRescanButton key="request-rescan" />
                    );
                default: 
                    return (<></>);
            }
        });
    }, [auditVarianceType]);

    return (
        <Stack
            direction="row"
            spacing={2}
            >
            {component}
        </Stack>
    );
}

export { OptionsResolver }