import { DspAudit, GetDspAuditsResponse } from "types/dsp/audits"

type AuditStateType = {
    audits: DspAudit[]
}

type AuditReducerAction =
    | { type: "SET_AUDITS", payload: GetDspAuditsResponse }

const auditsInitialState: AuditStateType = {
    audits: []
};

function AuditReducer(state: AuditStateType, action: AuditReducerAction): AuditStateType {
    switch (action.type) {
        case "SET_AUDITS":
            return {
                ...state,
                audits: action.payload.audits
            };
    }
}

export {
    auditsInitialState,
    AuditReducer
}