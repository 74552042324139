import { AxiosInstance } from "axios";
import { ApiService } from "./apiService";
import { DspPrinter, GetDspPrintersResponse } from "../types/dsp/setup";
import { GetDspPrintInstructionsRespose } from "../types/dsp/print";
import { CreateAuditResponse, DspAuditBin, DspAuditVarianceChange, FinishAuditResponse, GetDspAuditDetailsResponse, GetDspAuditsResponse, GetDspAuditTargetsResponse, GetDspAuditVarianceResponse } from "../types/dsp/audits";

class DspApiService extends ApiService {
    super(api: AxiosInstance) { }

    getAllPrinters(): Promise<DspPrinter[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/dsp/print/getallprinters");
                const getDsvPrintersResponse = response.data as GetDspPrintersResponse;
                resolve(getDsvPrintersResponse.printers);
            } catch (err) {
                reject(err);
            }
        });
    }

    getPrinterById(printerId: number): Promise<DspPrinter> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get(`dsp/print/getprinterbyid?printerid=${printerId}`);
                const getDsvPrintersResponse = response.data as GetDspPrintersResponse;
                resolve(getDsvPrintersResponse.printers[0]);
            } catch (err) {
                reject(err);
            }
        });
    }

    addPrinter(printerName: string, printerShortName: string, printerType: number, host: string, port: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("/dsp/print/addprinter", {
                    name: printerName,
                    shortName: printerShortName,
                    printerType: printerType,
                    host: host,
                    port: port
                });

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    updatePrinter(printerId: number, printerName: string, printerShortName: string, printerType: number, host: string, port: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.patch("/dsp/print/updateprinter", {
                    printerId: printerId,
                    name: printerName,
                    shortName: printerShortName,
                    printerType: printerType,
                    host: host,
                    port: port
                });

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    deletePrinter(printerId: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.delete(`/dsp/print/deleteprinter?printerId=${printerId}`);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    generatePrintInstructions(requestedItems: string[], labelType: number): Promise<GetDspPrintInstructionsRespose> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("/dsp/label/generateprintinstructions", {
                    requestedItems: requestedItems,
                    labelType: labelType,
                });

                const getPrintInstructionsResponse = response.data as GetDspPrintInstructionsRespose;

                resolve(getPrintInstructionsResponse)
            } catch (err) {
                reject(err);
            }
        });
    }

    getAllAudits(): Promise<GetDspAuditsResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/dsp/audit/getallaudits");
                
                const getDspAuditsResponse = response.data as GetDspAuditsResponse;
                resolve(getDspAuditsResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    createAudit(auditName: string): Promise<CreateAuditResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("/dsp/audit/createAudit", {
                    auditName: auditName
                });
                
                const createAuditResponse = response.data as CreateAuditResponse;

                resolve(createAuditResponse);
            } catch (err) {
                reject(err);
            }
        })
    }

    deleteAudit(auditId: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.delete(`/dsp/audit/deleteAudit?auditId=${auditId}`);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    getAuditTargets(): Promise<GetDspAuditTargetsResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/dsp/audit/getaudittargets");

                const getDspAuditTargetsResponse = response.data as GetDspAuditTargetsResponse;
                resolve(getDspAuditTargetsResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    getAudit(auditId: number): Promise<GetDspAuditsResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get(`/dsp/audit/getaudit?auditId=${auditId}`);

                const getDspAuditsResponse = response.data as GetDspAuditsResponse;
                resolve(getDspAuditsResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    updateAuditName(auditId: number, auditName: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const escapedAuditName = encodeURIComponent(auditName);
                const response = await this.api.patch(`/dsp/audit/updateauditname?auditId=${auditId}&auditName=${escapedAuditName}`);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    setAuditBins(auditId: number, bins: DspAuditBin[]): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("/dsp/audit/setauditbins", {
                    auditId: auditId,
                    bins: bins
                });
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    getAuditDetails(auditId: string): Promise<GetDspAuditDetailsResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get(`/dsp/audit/getauditdetails?auditId=${auditId}`);
                
                const getDspAuditDetailsResponse = response.data as GetDspAuditDetailsResponse;
                resolve(getDspAuditDetailsResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    getAuditVariance(auditId: string): Promise<GetDspAuditVarianceResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get(`/dsp/audit/getauditvariance?auditId=${auditId}`);

                const getDspAuditVarianceResponse = response.data as GetDspAuditVarianceResponse;
                resolve(getDspAuditVarianceResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    cancelAudit(auditId: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.patch(`/dsp/audit/cancelaudit?auditId=${auditId}`);
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    startAudit(auditId: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.patch(`/dsp/audit/startaudit?auditId=${auditId}`);

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    updateBinVariance(varianceChanges: DspAuditVarianceChange[]): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.patch("/dsp/audit/updatebinvariance", {
                    varianceChanges: varianceChanges
                });
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    flagBinsForRescan(auditBinIds: number[]): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.patch(`/dsp/audit/flagbinsforrescan`, {
                    auditBinIds: auditBinIds
                });

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve()
            } catch (err) {
                reject(err);
            }
        });
    }

    finishAudit(auditId: number): Promise<FinishAuditResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post(`/dsp/audit/finishaudit?auditid=${auditId}`);

                const finishAuditResponse = response.data as FinishAuditResponse;
                resolve(finishAuditResponse);
            } catch (err) {
                reject(err);
            }
        })
    }
}

export { DspApiService }