import { Box } from "@mui/material";
import { DataGrid, GridCallbackDetails, GridColDef, GridColumnVisibilityModel, GridPaginationModel, GridSlotsComponent, GridSortingInitialState } from "@mui/x-data-grid";
import { GridApiCommunity } from "@mui/x-data-grid/internals";

type GenericDataGridProps = {
    apiRef?: React.MutableRefObject<GridApiCommunity> | undefined
    columns: GridColDef[]
    columnVisibilityModel?: GridColumnVisibilityModel | undefined
    initialStatePagination?: Partial<GridPaginationModel> | undefined
    initialStateSorting?: GridSortingInitialState | undefined
    onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel, details: GridCallbackDetails) => void | undefined
    pageSizeOptions?: number[] | undefined
    rows: any[]
    slots?: Partial<GridSlotsComponent> | undefined
}

function GenericDataGrid(props: GenericDataGridProps): JSX.Element {
    return (
        <Box
            sx={{
              flex: 1,
              overflow: "auto"  
            }}
            >
            <DataGrid
                apiRef={props.apiRef}
                autoHeight={true}
                columns={props.columns}
                columnVisibilityModel={props.columnVisibilityModel}
                initialState={{
                    columns: {
                        columnVisibilityModel: props.columnVisibilityModel
                    },
                    pagination: {
                        paginationModel: props.initialStatePagination
                    },
                    sorting: props.initialStateSorting
                }}
                loading={props.rows.length === 0}
                onColumnVisibilityModelChange={props.onColumnVisibilityModelChange}
                pageSizeOptions={props.pageSizeOptions}
                rows={props.rows}
                slots={props.slots}
                />
        </Box>
        
    );
}

export { GenericDataGrid }
export type { GenericDataGridProps }