import { DeleteButton } from "../../../../../../base/crud";
import { useDspAuditContext } from "../../../context";

type AuditDeleteButtonProps = {
    deleteId: number
    disabled: boolean
}

function AuditDeleteButton(props: AuditDeleteButtonProps): JSX.Element {
    const { cancelCreateAudit, reloadData } = useDspAuditContext();

    const onClickDeleteButton = (deleteId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            cancelCreateAudit(deleteId)
                .then(() => {
                    reloadData();
                    resolve();
                })
                .catch((err) => {
                    console.error("Unable to cancel audit", err);
                    reject(err);
                });
        });
    }

    return (
        <DeleteButton
            deleteAction={onClickDeleteButton}
            deleteId={props.deleteId}
            disabled={props.disabled}
            />
    );
}

export { AuditDeleteButton }