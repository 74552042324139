import React from "react";
import { Stack, Typography } from "@mui/material";
import { PrintForm, PrintVinLabelsFormValues } from "./form";
import { PrintTemplateResolver } from "../../base/print/printTemplateResolver";
import { useApiService } from "../../base/providers";
import { PrintableTemplateHandle } from "types/print/PrintableTemplateHandle";
import { GetPrintInstructionsRespose, Stock } from "types/dsv/print";
import { BackToAuditHistoryButton } from "../audit/backToAuditHistoryButton";

function DsvPrintView(): JSX.Element {
    const { apiService } = useApiService();
    const [foundStock, setFoundStock] = React.useState<Stock[]>([]);
    const printTemplateRef = React.useRef<PrintableTemplateHandle>(null);

    const clickSubmitCallback = React.useCallback((formValues: PrintVinLabelsFormValues): void => {
        const stockNumbers = formValues.stockNumbersStr.split(",");
        apiService.dsv.generatePrintInstructions(stockNumbers, Number(formValues.selectedPrinterIdStr))
            .then((getPrintInstructionsResponse: GetPrintInstructionsRespose) => setFoundStock(getPrintInstructionsResponse.foundStock))
            .catch((err) => console.error("An error occurred while printing", err));
    }, [apiService]);

    React.useEffect(() => {
        if (!foundStock || foundStock.length === 0) {
            return;
        }

        setTimeout(() => {
            printTemplateRef.current?.print();
        }, 0);
    }, [foundStock]);

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <BackToAuditHistoryButton />
            <Typography variant="h4">Print Labels</Typography>
            <PrintForm clickSubmitCallback={clickSubmitCallback} />
            <PrintTemplateResolver
                ref={printTemplateRef}
                partDetails={[]}
                foundStock={foundStock}
                printType="vin"
                />
        </Stack>
    );
}

export { DsvPrintView }