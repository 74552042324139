import React from "react";
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { Tooltip } from '@mui/material';

type HasSupersessionsFlagProps = {
    hasSupersessions: boolean
}

function HasSupersessionsFlag({ hasSupersessions }: HasSupersessionsFlagProps): JSX.Element {
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            if (!hasSupersessions) {
                return (<></>);
            }

            return (
                <Tooltip title={`Supersession`}>
                    <SwapHorizontalCircleIcon color="error" />
                </Tooltip>
            );
        });
    }, [hasSupersessions]);

    return(component);
}

export { HasSupersessionsFlag }